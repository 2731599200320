import React, { useContext, useEffect, useState } from 'react';
import { ContentListGlobal } from '../contentList/contentListGlobal';
import { useAuthCtx } from '../../../features/auth_v2/hooks/useAuthCtx';
import { NoAccessToAnyContent } from './NoAccessToAnyContent';
import { CollapseContainer } from './styles';
import { GlobalFiltersContext } from './GlobalFiltersContext';
import { IAChat } from '../global/IAChat';

export const Home = () => {
  const { session } = useAuthCtx();

  const { searchType } = useContext(GlobalFiltersContext);

  const [hasAccessToPeriodics, setHasAccessToPeriodics] = useState(false);
  const [hasAccessToBooks, setHasAccessToBooks] = useState(false);
  const [hasAccessToVideos, setHasAccessToVideos] = useState(false);
  const [hasAccessToInformatives, setHasAccessToInformatives] = useState(false);

  const contractedTags = session.client.contracted_tags || [];

  useEffect(() => {
    setHasAccessToPeriodics(
      contractedTags.some((result) => result.id.startsWith('periodic'))
    );

    setHasAccessToBooks(
      contractedTags.some((result) => result.id.startsWith('book'))
    );

    setHasAccessToVideos(
      contractedTags.some((result) => result.id.startsWith('video'))
    );
    setHasAccessToInformatives(
      contractedTags.some((result) => result.id.startsWith('news'))
    );
  }, []);

  const hasAccesToSomeContent =
    hasAccessToPeriodics ||
    hasAccessToBooks ||
    hasAccessToVideos ||
    hasAccessToInformatives;

  return (
    <>
      {hasAccesToSomeContent ? <></> : <NoAccessToAnyContent />}
      <CollapseContainer>
        {searchType === 'ai' ? <IAChat /> : <ContentListGlobal />}
      </CollapseContainer>
    </>
  );
};

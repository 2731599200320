import React, { useContext, useState } from 'react';
import { Button } from 'antd';
import { Image as ImageGlobal } from './ImageGlobal';
import { Author } from '../../book/components/common/Author';
import { Tags } from '../../book/components/common/Tags';
import { Year } from '../../book/components/common/Year';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getChapterType } from '../../book/helpers/getChapterType';
import { ContentTypeTag } from '../../../../components/DefaultContentListItem/TagsRenderer/ContentTypeTag';
import { ReferenceBtns } from '../../video/styles';
import { Bibliographic } from '../../book/components/common/Bibliographic';
import { AddToFolder } from './AddToFolderGlobal';
import { http_client } from '../../../../util/http_client';
import { getItemType } from '../../periodic/helpers/getItemType';
import { ContentTypeTagGlobal } from './ContentTypeTagGlobal';
import { GlobalResultsModal } from './GlobalResultsModal';
import { DescriptionGlobal } from './DescriptionGlobal';
import { Description as DescriptionInformative } from '../../informatives/components/common/Description';
import { InfoPeriodic } from './InfoPeriodic';
import { GlobalFiltersContext } from '../../home/GlobalFiltersContext';

const Container = styled.div`
  max-width: 1024px;
  display: flex;
  margin-bottom: 32px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Left = styled.div`
  margin-right: 32px;
`;

const Right = styled.div`
  @media (max-width: 1024px) {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Infos = styled.div`
  margin-bottom: 16px;
`;

const Title = styled.h2`
  font-size: 16px !important;

  * {
    font-weight: 500 !important;
  }

  @media (max-width: 1024px) {
    font-size: 14px !important;
    text-align: center;
  }
`;

const TitleAI = styled.h2`
  font-size: 14px !important;

  * {
    font-weight: 500 !important;
  }

  @media (max-width: 1024px) {
    font-size: 14px !important;
    text-align: center;
  }
`;

const Highlight = styled.div`
  max-width: 1000px;
  padding-left: 212px;

  em {
    background: rgba(255, 255, 0, 0.3) !important;
  }
`;

const HighlightAI = styled.div`
  max-width: 780px;
  padding-left: 0px;

  em {
    background: rgba(255, 255, 0, 0.3) !important;
  }
`;

const HighlightContainer = styled.div`
  padding: 12px;
  border-left: 3px solid #d7282f;
  margin-bottom: 10px;
  max-width: 475px;
  b {
    font-size: 16px;
  }
`;

const HighlightContainerAI = styled.div`
  padding: 12px;
  border-left: 3px solid #d7282f;
  margin-bottom: 10px;
  max-width: 630px;
  b {
    font-size: 16px;
  }
`;

const HighlightRight = styled.div`
  margin-top: -32px;
  .filter-btn {
    background: none transparent;
    border: 2px solid #003a70;
    color: #003a70;
    min-width: min-content;
    width: auto;
    outline: none;
    border-radius: 12px;
    font-weight: 500;
    padding: 11px 20px;
    margin: 0 10px 0 0;
    height: auto;
    line-height: 1;
    font-size: 14px;
    position: relative;

    &:hover,
    &:focus,
    &:active {
      border: 2px solid #003a70;
      color: #003a70;
    }
  }
  @media (max-width: 1024px) {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const HighlightDiv = styled.div`
  margin-top: -160px;
`;

const HighlightTitle = styled.h2`
  text-transform: uppercase;
  font-size: 14px !important;
  color: #2f2f2f !important;
  * {
    font-weight: 500 !important;
  }
`;

const ButtonContainer = styled.div`
  padding: 12px;
  padding-left: 0;
  margin-top: 25px;
  margin-bottom: 10px;
`;

const ChapterTitle = styled.span`
  display: inline-block;
`;

const ChapterTitleMargin = styled.span`
  display: inline-block;
  margin-top: 8px;
`;

export const ListGlobal = ({
  items,
  isAIChat
}: {
  items: any[];
  isAIChat?: boolean;
}) => {
  const { authorData } = useContext(GlobalFiltersContext);
  const HighlightComponent = isAIChat ? HighlightAI : Highlight;
  const HighlightContainerComponent = isAIChat
    ? HighlightContainerAI
    : HighlightContainer;
  const TitleComponent = isAIChat ? TitleAI : Title;
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<any[]>([]);
  const [currentItem, setCurrentItem] = useState<any>({});

  const [showBibliografy, setShowBibliografy] = useState<boolean>(false);
  const [bibliografyItem, setBibliografyItem] = useState<string>('');
  const [showAddToFolder, setShowAddToFolder] = useState<boolean>(false);
  const [folderItem, setFolderItem] = useState<any | unknown>({});

  if (!items) return;

  const hasInformatives = items.find((i) => i.type === 'informative');

  const contentTypes = {
    book: 'livro',
    periodic: 'revista',
    informative: 'informativo/jacoby',
    video: 'video'
  };

  const contentTypesText = {
    book: 'livro',
    periodic: 'revista',
    informative: 'informativo',
    video: 'video'
  };

  const link = (item: any, childId?: string) => {
    if (item.type === 'informative') {
      return `/v2/${contentTypes[item.type]}/${
        item.publish_date.split('T')[0]
      }?searchpage=1&origin=search`;
    }
    if (item.type === 'video') {
      return `/conteudo/videos/${String(item.id).replaceAll('V', '')}`;
    }
    return `/v2/${contentTypes[item.type]}/${String(item.id)
      .split('-')
      .join('/')}${childId ? `/${childId}?searchpage=1&origin=search` : ''}`;
  };

  const toggleModal = (data: any[], item: Partial<any>) => {
    setShowModal(!showModal);
    setModalData(data);
    setCurrentItem(item);
  };

  const getHighlights = (str: string): string[] => {
    const regex = /<mark>(.*?)<\/mark>/g;
    let m: any;
    const occurrences = [];
    while ((m = regex.exec(str)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      if (m && m[1]) {
        occurrences.push(m[1]);
      }
    }
    return occurrences.sort((a, b) => {
      if (a.length > b.length) return -1;
      if (a.length === b.length) return 0;
      return 1;
    });
  };

  const isHighlight = (compare: string, str: string) => {
    const regexStr = `<mark>(${compare}*?)<\/mark>`;
    const regex = new RegExp(regexStr, 'g');
    const result = regex.exec(str);
    return !!result && result[1];
  };

  const getTitleHighlight = (item: any, content: any) => {
    let itemTitle = content.parent_title;
    if (
      item &&
      item.highlight_internal_titles &&
      item.highlight_internal_titles.length
    ) {
      for (const t of item.highlight_internal_titles) {
        const highlights = getHighlights(t);
        const replaced = [];
        for (const h of highlights) {
          if (replaced.includes(h)) continue;
          if (isHighlight(h, itemTitle)) continue;
          const lowerCase = String(h).toLocaleLowerCase();
          itemTitle = itemTitle.replaceAll(
            new RegExp('\\b' + lowerCase + '\\b', 'g'),
            `<mark>${lowerCase}</mark>`
          );
          replaced.push(lowerCase);
          const firstUppercase =
            lowerCase[0].toUpperCase() + lowerCase.slice(1);
          itemTitle = itemTitle.replaceAll(
            new RegExp('\\b' + firstUppercase + '\\b', 'g'),
            `<mark>${firstUppercase}</mark>`
          );
          replaced.push(firstUppercase);
          const uppercase = lowerCase.toUpperCase();
          itemTitle = itemTitle.replaceAll(
            new RegExp('\\b' + uppercase + '\\b', 'g'),
            `<mark>${uppercase}</mark>`
          );
          replaced.push(uppercase);
        }
      }
    }
    return itemTitle;
  };

  const globalContents: any[] = [];
  if (items && items.length) {
    for (const item of items) {
      if (item && item.highlight_contents && item.highlight_contents.length) {
        for (const c of item.highlight_contents) {
          if (item.type === 'informative' && c.highlight.length) {
            for (const highlight of c.highlight) {
              const highlightExists = globalContents.find(
                (bc: any) => bc.id === item.id
              );
              if (highlightExists) {
                highlightExists.txts.push(highlight);
                continue;
              }
              globalContents.push({
                id: item.id,
                parent_id: c.parent_id,
                parent_title: '',
                parent_type: '',
                page: c.page,
                txts: [highlight]
              });
              continue;
            }
            continue;
          }
          if (!c.highlight && !c.highlight.length) continue;
          // const highlight = c.highlight && c.highlight[0] ? c.highlight[0] : '';

          const contents = Array.isArray(item.contents)
            ? item.contents
            : [{ ...item.contents }];
          const content: any = contents.find(
            (cp: any) => cp.parent_id === c.parent_id
          );
          if (!content) {
            continue;
          }

          for (const h of c.highlight) {
            const contentExists = globalContents.find(
              (bc: any) => bc.id === item.id && bc.parent_id === c.parent_id
            );
            if (contentExists) {
              contentExists.txts.push(h);
              continue;
            }

            // REGRA: Caso algum autor seja selecionado, deve eliminar highlights em Jurisprudências.
            if (
              (content.parent_type === 'jurisprudence' ||
                content.parent_type === 'jurisprudential-trends') &&
              authorData &&
              authorData.length
            ) {
              continue;
            }
            const titleHighlight = getTitleHighlight(item, content);
            globalContents.push({
              id: item.id,
              parent_id: c.parent_id,
              parent_title: titleHighlight || content.parent_title,
              parent_type:
                content && content.parent_type
                  ? String(content.parent_type)
                  : '',
              page: c.page,
              txts: [h]
            });
          }
        }
      } else {
        if (!item || !item.contents) continue;
        const contents = Array.isArray(item.contents)
          ? item.contents
          : [{ ...item.contents }];
        if (contents) {
          for (const c of contents) {
            const titleHighlight = String(getTitleHighlight(item, c) || '');
            if (titleHighlight.includes('<mark>')) {
              globalContents.push({
                id: item.id,
                parent_id: c.parent_id,
                parent_title: titleHighlight,
                page: c.page,
                txts: []
              });
            }
          }
        }
      }
    }
  }

  const openBibliografy = (item: any) => {
    const contentType = item.type || '';
    setBibliografyItem('');
    const [contentId, editionId] = String(item.id).split('-');
    const url = `${process.env.REACT_APP_BIBLIOGRAPHIC_REFERENCE}/${contentType}s/${contentId}/editions/${editionId}/reference`;
    http_client.get<any>(url).then((res) => {
      setBibliografyItem(res.data.citation);
    });
    setShowBibliografy(true);
  };

  const getTagType = (type: string, item: any) => {
    if (type === 'book') {
      return getChapterType(item.parent_title);
    }
    if (type === 'periodic') {
      return getItemType(item.parent_type);
    }
    return '';
  };

  const openAddToFolder = (item: any) => {
    setShowAddToFolder(true);
    setFolderItem(item);
  };

  const getContentTitle = (item: any, subtitle?: string) => {
    if (['book', 'informative', 'video'].includes(item.type)) {
      return `${
        item && item.highlight_title && item.highlight_title[0]
          ? item.highlight_title[0]
          : item.title
      } ${subtitle ? ` : ${subtitle}` : ''}`;
    }
    if (item.type === 'periodic') {
      return `${
        item &&
        item.highlight_parent_title_1 &&
        item.highlight_parent_title_1[0]
          ? item.highlight_parent_title_1[0]
          : item.parent_title_1
      } ${subtitle ? ` : ${subtitle}` : ''}`;
    }
    return '';
  };

  const getStringTruncated = (str: string) =>
    str.length > 88 ? `${str.substring(0, 88)}...` : str;

  const getStringPlain = (str: string) =>
    str.replaceAll('<mark>', '').replaceAll('</mark>', '');

  return (
    <>
      <GlobalResultsModal
        key={`global-result-modal`}
        showModal={showModal}
        isAIChat={isAIChat}
        toggleModal={toggleModal}
        modalData={modalData}
        link={link}
        currentItem={currentItem}
      />
      <Bibliographic
        options={{
          showBibliografy,
          setShowBibliografy,
          bibliografyItem
        }}
      />
      <AddToFolder
        options={{
          showAddToFolder,
          setShowAddToFolder,
          folderItem
        }}
      />
      {items.map((item: any, index: number) => {
        const globalContent =
          globalContents && globalContents.length
            ? globalContents.filter((b) => b.id === item.id)
            : [];
        let isTxtsHide = false;
        globalContent.forEach((b) => {
          if (b.txts && b.txts.length > 3) isTxtsHide = true;
        });
        const subtitle =
          item && item.entity && item.entity.subtitle
            ? item.entity.subtitle
            : '';

        return (
          <div
            key={index}
            style={
              index !== 0
                ? { marginTop: hasInformatives ? '110px' : '45px' }
                : {}
            }>
            <Container style={isAIChat ? { maxWidth: 580 } : {}}>
              <Left>
                <Link to={link(item)} target="_blank">
                  <ImageGlobal item={item} isAIChat={isAIChat} />
                </Link>
              </Left>
              <Right style={isAIChat ? { maxWidth: 470 } : {}}>
                <ContentTypeTagGlobal type={contentTypesText[item.type]} />
                <TitleComponent>
                  <Link
                    to={link(item)}
                    title={getStringPlain(
                      getContentTitle(item, subtitle) || ''
                    )}
                    target="_blank"
                    dangerouslySetInnerHTML={{
                      __html: getStringTruncated(
                        getContentTitle(item, subtitle) || ''
                      )
                    }}
                    style={{ fontWeight: 'bold', color: '#000000' }}
                  />
                </TitleComponent>
                {item.type !== 'informative' && (
                  <Infos>
                    {item.type === 'periodic' ? (
                      <InfoPeriodic item={item} />
                    ) : (
                      <Year item={item} />
                    )}
                    <Author item={item} />
                    <Tags item={item} />
                  </Infos>
                )}
                {!isAIChat ? (
                  <>
                    {item.type === 'informative' ? (
                      <DescriptionInformative item={item} />
                    ) : (
                      <DescriptionGlobal item={item} />
                    )}
                  </>
                ) : (
                  <></>
                )}
                <ReferenceBtns>
                  <Button onClick={() => openAddToFolder(item)} icon="folder">
                    Adicionar à pasta
                  </Button>
                  {['book', 'periodic'].includes(item.type) ? (
                    <Button onClick={() => openBibliografy(item)} icon="edit">
                      Referência bibliográfica
                    </Button>
                  ) : (
                    <></>
                  )}
                </ReferenceBtns>
              </Right>
            </Container>
            {globalContent && globalContent.length ? (
              <>
                {item.type === 'informative' ? (
                  <HighlightRight>
                    <HighlightDiv>
                      <HighlightComponent>
                        {globalContent
                          .filter((_, idx) => idx <= 2)
                          .map((bc, i) => (
                            <HighlightContainerComponent
                              key={`highlight-container-${i}`}
                              style={isAIChat ? { marginTop: 165 } : {}}>
                              <HighlightTitle>
                                <Link
                                  to={link(item, bc.parent_id)}
                                  target="_blank">
                                  {getTagType(item.type, bc) ? (
                                    <>
                                      <ContentTypeTag>
                                        {getTagType(item.type, bc)}
                                      </ContentTypeTag>
                                      <br />
                                      <ChapterTitleMargin>
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: bc.parent_title
                                          }}
                                        />
                                      </ChapterTitleMargin>
                                    </>
                                  ) : (
                                    <ChapterTitle>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: bc.parent_title
                                        }}
                                      />
                                    </ChapterTitle>
                                  )}
                                </Link>
                              </HighlightTitle>
                              {bc.txts
                                .filter((_, idx) => idx <= 2)
                                .map((t, index) => (
                                  <div
                                    key={`content-${index}`}
                                    className="description highlight_description"
                                    dangerouslySetInnerHTML={{
                                      __html: `<b>“</b>${t}...<b>”</b>`
                                    }}
                                  />
                                ))}
                              {bc.txts && bc.txts.length > 3 ? (
                                <div className="description highlight_description">
                                  ...
                                </div>
                              ) : (
                                <></>
                              )}
                            </HighlightContainerComponent>
                          ))}
                      </HighlightComponent>
                    </HighlightDiv>
                  </HighlightRight>
                ) : (
                  <HighlightRight>
                    <HighlightComponent>
                      {globalContent
                        .filter((_, idx) => idx <= 2)
                        .map((bc, i) => (
                          <HighlightContainerComponent
                            key={`highlight-container-${i}`}>
                            <HighlightTitle>
                              <Link
                                to={link(item, bc.parent_id)}
                                target="_blank">
                                {getTagType(item.type, bc) ? (
                                  <>
                                    <ContentTypeTag>
                                      {getTagType(item.type, bc)}
                                    </ContentTypeTag>
                                    <br />
                                    <ChapterTitleMargin>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: bc.parent_title
                                        }}
                                      />
                                    </ChapterTitleMargin>
                                  </>
                                ) : (
                                  <ChapterTitle>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: bc.parent_title
                                      }}
                                    />
                                  </ChapterTitle>
                                )}
                              </Link>
                            </HighlightTitle>
                            {bc.txts
                              .filter((_, idx) => idx <= 2)
                              .map((t, index) => (
                                <div
                                  key={`content-${index}`}
                                  className="description highlight_description"
                                  dangerouslySetInnerHTML={{
                                    __html: `<b>“</b>${t}...<b>”</b>`
                                  }}
                                />
                              ))}
                            {bc.txts && bc.txts.length > 3 ? (
                              <div className="description highlight_description">
                                ...
                              </div>
                            ) : (
                              <></>
                            )}
                          </HighlightContainerComponent>
                        ))}
                    </HighlightComponent>
                  </HighlightRight>
                )}
              </>
            ) : (
              <></>
            )}
            {(globalContent &&
              globalContent.length &&
              globalContent.length > 3) ||
            isTxtsHide ? (
              <HighlightRight>
                <HighlightComponent>
                  <ButtonContainer>
                    <Button
                      className="filter-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleModal(globalContent, item);
                      }}>
                      Ver mais
                    </Button>
                  </ButtonContainer>
                </HighlightComponent>
              </HighlightRight>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </>
  );
};

import styled from 'styled-components';
import { Checkbox } from 'antd';

export const Container = styled.div`
  margin: 16px;
  display: block;

  p {
    font-size: 1.2em;

    span {
      font-size: 1em;
      color: #004c92;
      font-weight: 600;
    }

    button {
      font-size: 0.9em;
      background: none;
      border: 2px solid #d7282f;
      margin-top: 12px;
      font-weight: 500;
      color: #d7282f;
      border-radius: 5px;
      cursor: pointer;
      margin-left: 10px;
      transform: scale(1);
      transition: all 0.1s ease-in-out;

      &:hover,
      &:focus {
        transform: scale(1.02);
      }

      &:active {
        transform: scale(0.98);
      }
    }
  }
`;

export const ContainerNoAccess = styled.div`
  text-align: center;
  padding: 60px 0;

  h1 {
    font-size: 2em;
  }

  p {
    font-size: 1.2em;
  }
`;

export const CollapseContainer = styled.div`
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-family: var(--title-font-family);
    font-weight: 700;
    font-size: 1.5rem;
    padding: 12px 16px 12px 22px;
  }

  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: default !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header span {
    font-weight: 300;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    left: auto;
    right: 0;
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    right: 22px;
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    h2 {
      font-weight: 700;
      font-size: 1.5rem;
      margin-bottom: 0;
    }
    a {
      span {
        background: none;
        border: 0 none;
        margin-top: 12px;
        margin-right: 35px;
        font-weight: 500;
        color: #004c92;
      }
    }
  }
`;

export const GlobalNavBarClear = styled.div`
  .ant-btn-danger {
    background: none transparent;
    border: 2px solid #d7282f;
    color: #d7282f;
    min-width: min-content;
    width: auto;
    outline: none;
    border-radius: 12px;
    font-weight: 500;
    padding: 11px 20px;
    margin: 0 10px 0 0;
    height: auto;
    line-height: 1;
    font-size: 14px;
    position: relative;

    &:hover,
    &:focus,
    &:active {
      border: 2px solid #d7282f;
      color: #d7282f;
    }

    .ant-badge {
      position: absolute;
      top: -11px;
      right: -11px;

      p {
        color: #fff;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
`;

export const GlobalNavBarContainer = styled.div`
  .filter-btn,
  .filter-btn-alter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35.2px;
    font-size: 14px;
    font-weight: 400;
    margin-right: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    color: #323232;
    transition: background-color 0.1s ease-in-out, font-size 0.1s ease-in-out;

    &:hover {
      background-color: #004c92;
      border: 1px solid #ccc;
      color: #fff;
    }

    &:focus {
      border-color: #fff;
    }

    .ant-badge {
      position: absolute;
      top: -11px;
      right: -11px;

      p {
        color: #fff;
        font-size: 12px;
        font-weight: 700;
      }
    }

    .anticon-down {
      font-size: 10px;
      margin-left: 12px;
      margin-top: 2px;
    }
  }

  .filter-btn-alter {
    background-color: #004c92;
    color: #fff;
    border-color: #004c92;

    &:hover,
    &:focus,
    &:active {
      background-color: #004c92;
      border-color: #004c92;
    }
  }
`;

export const GlobalNavBarDates = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 300px;

  .ant-select {
    width: 110px;
  }

  > span {
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const GlobalNavBarResults = styled.div`
  .filter-search {
    width: 300px;
    display: block;
  }

  .results {
    margin-top: 15px;
    width: 300px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .error,
  .empty {
    width: 300px;
    text-align: center;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 12px;
    line-height: 2;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .error {
    background: #ffcaca;
  }

  .empty {
    background: #feffca;
  }

  .anticon-loading {
    margin: 10px auto 0 auto;
    display: block;
  }

  .ant-checkbox-wrapper {
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    margin: 0;
    user-select: none;
    padding: 2px 5px;

    &:nth-child(odd) {
      background: #f6f6f6;
    }

    &:hover {
      background: #f1f1f1;
    }

    + .ant-checkbox-wrapper {
      margin: 0;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background: #003a70;
      border-color: #003a70;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #003a70;
  }

  .selecteds {
    width: 300px;
    display: block;
    margin-bottom: 10px;
    user-select: none;

    .ant-tag {
      overflow: hidden;
      position: relative;
      padding: 0 25px 0 5px;
      max-width: 300px;

      .anticon-close {
        background: #003a70;
        color: #fff;
        padding: 7px;
        margin: -2px -3px -3px 0;
        border-radius: 0 3px 3px 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;

        &:hover,
        &:focus,
        &:active {
          background: #01498d;
          color: #fff;
        }
      }
    }
  }
`;

export const GlobalOrder = styled.div`
  margin-left: 10px;
  .ant-select {
    width: 200px;
  }
  .ant-select-selection {
    border-radius: 10px !important;
  }
`;

export const CheckboxInput = styled(Checkbox)`
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: inherit;
  }
`;

import React from 'react';
import CalendarIcon from '../../../../assets/images/calendar-icon-4.svg';
import { PeriodicInfoImg, PeriodicInnerFields } from '../../periodic/styles';

export const InfoPeriodic = ({ item }) => {
  const year = (el: any) => new Date(el.start_month).getUTCFullYear();
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
  const startMonth = (el: any) =>
    capitalize(
      String(
        new Date(el.start_month).toLocaleString('pt-BR', {
          month: 'short',
          timeZone: 'UTC'
        })
      )
    );
  const endMonth = (el: any) =>
    capitalize(
      String(
        el.end_month
          ? new Date(el.end_month).toLocaleString('pt-BR', {
              month: 'short',
              timeZone: 'UTC'
            })
          : ''
      )
    );

  return (
    <PeriodicInnerFields>
      <PeriodicInfoImg src={CalendarIcon} />
      <span>
        {`${`Número ${String(item.title)
          .replaceAll('Edição ', '')
          .replaceAll('Número ', '')}`}, Ano ${year(item)}, ${startMonth(
          item
        )}${endMonth(item) ? `/${endMonth(item)}` : ''} - ${year(item)}`}
      </span>
    </PeriodicInnerFields>
  );
};

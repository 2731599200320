/* eslint-disable no-console */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import iconDate from '../../../../assets/images/calendar-icon-4.svg';
import IconChapter from '../../../../assets/images/quote.svg';
import iconAuthor from '../../../../assets/icon-author.svg';
import { useSearchFilter } from '../../../pesquisa/hooks/useSearchFilter';
import {
  CardContainer,
  Info,
  InfoPopover,
  ModalInner,
  ModalResults,
  ModalStyled,
  ResultsButton,
  Title
} from './styles';
import { OccurrenceContents } from './types/occurrences';
import { ContentTypeTagGlobal } from '../../global/components/ContentTypeTagGlobal';

type IImgError = React.SyntheticEvent<HTMLImageElement, Event>;

interface IParams {
  id: string;
  edition: string;
  title: string;
  date: string;
  author: string;
  desc: string;
  link: string;
  contents: OccurrenceContents[];
  highlights: any;
  ref?: any;
}

export const Card = ({
  id,
  edition,
  title,
  date,
  author,
  link,
  contents,
  highlights
}: IParams) => {
  const { filter } = useSearchFilter();
  const [titlePopover, setTitlePopover] = useState(false);
  const [datePopover, setDatePopover] = useState(false);
  const [authorPopover, setAuthorPopover] = useState(false);

  const handleTitlePopover = () => setTitlePopover(!titlePopover);
  const handleDatePopover = () => setDatePopover(!datePopover);
  const handleAuthorPopover = () => setAuthorPopover(!authorPopover);

  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const handleOk = () => setVisible(false);

  const base = `https://${process.env.REACT_APP_PUBLIC_BUCKET}.s3-sa-east-1.amazonaws.com`;
  const placeholder = `${base}/books/cover/no-cover.jpg`;
  const onImgError = (e: IImgError) => (e.currentTarget.src = placeholder);
  const idSplited = String(id || '').split('-');
  const bookNumId = idSplited && idSplited[0] ? idSplited[0] : '';
  const editionNumId = idSplited && idSplited[1] ? idSplited[1] : '';
  const coverSrc = `${base}/books/cover/${bookNumId}/editions/${editionNumId}.jpg`;

  const getTitle = (item: any) => {
    try {
      return contents.filter((c) => c.chapter_id === item.chapter_id)[0]
        .chapter_title;
    } catch (error) {
      return '';
    }
  };

  function converterData(data: string): string {
    const [ano, mes, dia] = data.split('-');
    return `${dia}/${mes}/${ano}`;
  }

  return (
    <CardContainer>
      <Link className="cover" to={link}>
        <img src={coverSrc} alt={title} onError={onImgError} />
      </Link>
      <ContentTypeTagGlobal type="livro" />
      <Link className="link" to={link}>
        <Title
          onMouseEnter={handleTitlePopover}
          onMouseLeave={handleTitlePopover}>
          {title.length > 30 ? `${title.slice(0, 26)}...` : title.toUpperCase()}
          {titlePopover && <InfoPopover>{title}</InfoPopover>}
        </Title>
      </Link>
      <div className="info-wrapper">
        {author !== '' && date !== '' && (
          <Info
            className="info-date"
            onMouseEnter={handleDatePopover}
            onMouseLeave={handleDatePopover}>
            <img src={iconDate} alt={title} />
            <span>
              {edition ? `Edição ${edition}, Ano ` : ''}
              {converterData(date).slice(6, 10)}
            </span>
          </Info>
        )}
        {author !== '' && (
          <Info
            className="info-author"
            onMouseEnter={handleAuthorPopover}
            onMouseLeave={handleAuthorPopover}>
            <img src={iconAuthor} alt={title} />
            <span>
              {author.length > 16 ? `${author.slice(0, 16)}...` : author}
            </span>
            {authorPopover && <InfoPopover>{author}</InfoPopover>}
          </Info>
        )}
      </div>
      {filter.words !== '' && highlights ? (
        <>
          <ResultsButton onClick={showModal}>
            Ver {highlights.length} ocorrências
          </ResultsButton>
          <ModalStyled
            title={'Ocorrências'}
            closable={false}
            visible={visible}
            onOk={handleOk}
            okText="Fechar"
            cancelButtonProps={{ style: { display: 'none' } }}
            width={800}
            bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
            <ModalInner>
              {highlights.map((e: any, i: number) => (
                <div key={i}>
                  <Link
                    to={`/v2/livro/${id.replace('-', '/')}/${
                      e.chapter_id
                    }?searchpage=1`}>
                    <h4 className="title-chapter">
                      <img className="icon-chapter" src={IconChapter} alt="" />
                      {getTitle(e)}
                    </h4>
                  </Link>
                  {e.highlight.map((ee: any, ii: number) => (
                    <ModalResults key={ii}>
                      <span key={ii} dangerouslySetInnerHTML={{ __html: ee }} />
                      <span>...</span>
                    </ModalResults>
                  ))}
                </div>
              ))}
            </ModalInner>
          </ModalStyled>
        </>
      ) : null}
    </CardContainer>
  );
};

import React, { createContext, useState } from 'react';
import { OrderType, SearchType } from '../../../components/v2/searchHelpers';

interface GlobalFiltersContextData {
  aiSearchText: string;
  setAiSearchText: (v: string) => void;
  submitAI: number;
  updateDataAI: () => void;
  loadingAI: boolean;
  setLoadingAI: (v: boolean) => void;
  aiChat: object[];
  setAIChat: (v: object) => void;
  lastAIResult: any;
  setLastAIResult: (v: object) => void;

  searchType: SearchType;
  setSearchType: (v: SearchType) => void;

  totalGlobal: number;
  setTotalGlobal: (v: number) => void;
  isInSearchGlobal: boolean;
  setIsInSearchGlobal: (v: boolean) => void;
  loadingGlobal: boolean;
  setLoadingGlobal: (v: boolean) => void;

  submit: number;
  updateData: () => void;

  order: OrderType;
  setOrder: (v: OrderType) => void;

  page: Number;
  setPage: (v: Number) => void;

  advancedVisible: boolean;
  advancedData: any;
  setAdvancedVisible: (v: boolean) => void;
  setAdvancedData: (v: object[]) => void;

  typeVisible: boolean;
  typeSearchValue: string;
  typeLoading: boolean;
  typeLoaded: boolean;
  typeError: boolean;
  typeData: any;
  typeRI: number;
  setTypeVisible: (v: boolean) => void;
  setTypeSearchValue: (v: string) => void;
  setTypeLoading: (v: boolean) => void;
  setTypeLoaded: (v: boolean) => void;
  setTypeError: (v: boolean) => void;
  setTypeData: (v: any) => void;
  setTypeRI: (v: number) => void;

  areaVisible: boolean;
  areaSearchValue: string;
  areaLoading: boolean;
  areaLoaded: boolean;
  areaError: boolean;
  areaData: any;
  areaRI: number;
  setAreaVisible: (v: boolean) => void;
  setAreaSearchValue: (v: string) => void;
  setAreaLoading: (v: boolean) => void;
  setAreaLoaded: (v: boolean) => void;
  setAreaError: (v: boolean) => void;
  setAreaData: (v: any) => void;
  setAreaRI: (v: number) => void;

  dateVisible: boolean;
  dateLoading: boolean;
  dateLoaded: boolean;
  dateError: boolean;
  dateData: any;
  dateRI: number;
  setDateVisible: (v: boolean) => void;
  setDateLoading: (v: boolean) => void;
  setDateLoaded: (v: boolean) => void;
  setDateError: (v: boolean) => void;
  setDateData: (v: any) => void;
  setDateRI: (v: number) => void;

  authorVisible: boolean;
  authorSearchValue: string;
  authorLoading: boolean;
  authorLoaded: boolean;
  authorError: boolean;
  authorData: any;
  authorRI: number;
  setAuthorVisible: (v: boolean) => void;
  setAuthorSearchValue: (v: string) => void;
  setAuthorLoading: (v: boolean) => void;
  setAuthorLoaded: (v: boolean) => void;
  setAuthorError: (v: boolean) => void;
  setAuthorData: (v: any) => void;
  setAuthorRI: (v: number) => void;

  periodicVisible: boolean;
  periodicSearchValue: string;
  periodicLoading: boolean;
  periodicLoaded: boolean;
  periodicError: boolean;
  periodicData: any;
  periodicRI: number;
  setPeriodicVisible: (v: boolean) => void;
  setPeriodicSearchValue: (v: string) => void;
  setPeriodicLoading: (v: boolean) => void;
  setPeriodicLoaded: (v: boolean) => void;
  setPeriodicError: (v: boolean) => void;
  setPeriodicData: (v: any) => void;
  setPeriodicRI: (v: number) => void;

  appTypeVisible: boolean;
  appTypeSearchValue: string;
  appTypeData: any;
  setAppTypeVisible: (v: boolean) => void;
  setAppTypeSearchValue: (v: string) => void;
  setAppTypeData: (v: any) => void;

  bookVisible: boolean;
  bookSearchValue: string;
  bookLoading: boolean;
  bookLoaded: boolean;
  bookError: boolean;
  bookData: any;
  bookRI: number;
  setBookVisible: (v: boolean) => void;
  setBookSearchValue: (v: string) => void;
  setBookLoading: (v: boolean) => void;
  setBookLoaded: (v: boolean) => void;
  setBookError: (v: boolean) => void;
  setBookData: (v: any) => void;
  setBookRI: (v: number) => void;

  serieBookVisible: boolean;
  serieBookSearchValue: string;
  serieBookLoading: boolean;
  serieBookLoaded: boolean;
  serieBookError: boolean;
  serieBookData: any;
  serieBookRI: number;
  setSerieBookVisible: (v: boolean) => void;
  setSerieBookSearchValue: (v: string) => void;
  setSerieBookLoading: (v: boolean) => void;
  setSerieBookLoaded: (v: boolean) => void;
  setSerieBookError: (v: boolean) => void;
  setSerieBookData: (v: any) => void;
  setSerieBookRI: (v: number) => void;

  eventVisible: boolean;
  eventSearchValue: string;
  eventLoading: boolean;
  eventLoaded: boolean;
  eventError: boolean;
  eventData: any;
  eventRI: number;
  setEventVisible: (v: boolean) => void;
  setEventSearchValue: (v: string) => void;
  setEventLoading: (v: boolean) => void;
  setEventLoaded: (v: boolean) => void;
  setEventError: (v: boolean) => void;
  setEventData: (v: any) => void;
  setEventRI: (v: number) => void;

  serieVideoVisible: boolean;
  serieVideoSearchValue: string;
  serieVideoLoading: boolean;
  serieVideoLoaded: boolean;
  serieVideoError: boolean;
  serieVideoData: any;
  serieVideoRI: number;
  setSerieVideoVisible: (v: boolean) => void;
  setSerieVideoSearchValue: (v: string) => void;
  setSerieVideoLoading: (v: boolean) => void;
  setSerieVideoLoaded: (v: boolean) => void;
  setSerieVideoError: (v: boolean) => void;
  setSerieVideoData: (v: any) => void;
  setSerieVideoRI: (v: number) => void;
  clearFilters: () => void;
}

export const GlobalFiltersContext = createContext<GlobalFiltersContextData>(
  {} as GlobalFiltersContextData
);

export const GlobalFiltersProvider: React.FC = ({ children }) => {
  const [aiSearchText, setAiSearchText] = useState<string>('');
  const [submitAI, setSubmitAI] = useState<number>(0);
  const [loadingAI, setLoadingAI] = useState(false);
  const [aiChat, setAIChat] = useState<object[]>([]);
  const [lastAIResult, setLastAIResult] = useState<object>({});

  const [searchType, setSearchType] = useState<SearchType>('boolean');

  const [totalGlobal, setTotalGlobal] = useState(0);
  const [isInSearchGlobal, setIsInSearchGlobal] = useState(false);
  const [loadingGlobal, setLoadingGlobal] = useState(false);

  const [submit, setSubmit] = useState<number>(0);
  const [order, setOrder] = useState<OrderType>('date:desc');
  const [page, setPage] = useState<Number>(1);

  const [advancedVisible, setAdvancedVisible] = useState<boolean>(false);
  const [advancedData, setAdvancedData] = useState<object[]>([
    { type: 'AND', text: '', exact: false, searchInTitle: false }
  ]);

  const [dateVisible, setDateVisible] = useState<boolean>(false);
  const [dateLoading, setDateLoading] = useState<boolean>(false);
  const [dateLoaded, setDateLoaded] = useState<boolean>(false);
  const [dateError, setDateError] = useState<boolean>(false);
  const [dateData, setDateData] = useState<any>([]);
  const [dateRI, setDateRI] = useState<number>(0);

  const [typeVisible, setTypeVisible] = useState<boolean>(false);
  const [typeSearchValue, setTypeSearchValue] = useState<string>('');
  const [typeLoading, setTypeLoading] = useState<boolean>(false);
  const [typeLoaded, setTypeLoaded] = useState<boolean>(false);
  const [typeError, setTypeError] = useState<boolean>(false);
  const [typeData, setTypeData] = useState<any>([]);
  const [typeRI, setTypeRI] = useState<number>(0);

  const [areaVisible, setAreaVisible] = useState<boolean>(false);
  const [areaSearchValue, setAreaSearchValue] = useState<string>('');
  const [areaLoading, setAreaLoading] = useState<boolean>(false);
  const [areaLoaded, setAreaLoaded] = useState<boolean>(false);
  const [areaError, setAreaError] = useState<boolean>(false);
  const [areaData, setAreaData] = useState<any>([]);
  const [areaRI, setAreaRI] = useState<number>(0);

  const [authorVisible, setAuthorVisible] = useState<boolean>(false);
  const [authorSearchValue, setAuthorSearchValue] = useState<string>('');
  const [authorLoading, setAuthorLoading] = useState<boolean>(false);
  const [authorLoaded, setAuthorLoaded] = useState<boolean>(false);
  const [authorError, setAuthorError] = useState<boolean>(false);
  const [authorData, setAuthorData] = useState<any>([]);
  const [authorRI, setAuthorRI] = useState<number>(0);

  const [periodicVisible, setPeriodicVisible] = useState<boolean>(false);
  const [periodicSearchValue, setPeriodicSearchValue] = useState<string>('');
  const [periodicLoading, setPeriodicLoading] = useState<boolean>(false);
  const [periodicLoaded, setPeriodicLoaded] = useState<boolean>(false);
  const [periodicError, setPeriodicError] = useState<boolean>(false);
  const [periodicData, setPeriodicData] = useState<any>([]);
  const [periodicRI, setPeriodicRI] = useState<number>(0);

  const appTypeValues = [
    { key: 'doctrine', value: 'Doutrina' },
    { key: 'jurisprudence', value: 'Jurisprudência' }
  ];
  const [appTypeVisible, setAppTypeVisible] = useState<boolean>(false);
  const [appTypeSearchValue, setAppTypeSearchValue] = useState<string>('');
  const [appTypeData, setAppTypeData] = useState<any>(appTypeValues);

  const [bookVisible, setBookVisible] = useState<boolean>(false);
  const [bookSearchValue, setBookSearchValue] = useState<string>('');
  const [bookLoading, setBookLoading] = useState<boolean>(false);
  const [bookLoaded, setBookLoaded] = useState<boolean>(false);
  const [bookError, setBookError] = useState<boolean>(false);
  const [bookData, setBookData] = useState<any>([]);
  const [bookRI, setBookRI] = useState<number>(0);

  const [serieBookVisible, setSerieBookVisible] = useState<boolean>(false);
  const [serieBookSearchValue, setSerieBookSearchValue] = useState<string>('');
  const [serieBookLoading, setSerieBookLoading] = useState<boolean>(false);
  const [serieBookLoaded, setSerieBookLoaded] = useState<boolean>(false);
  const [serieBookError, setSerieBookError] = useState<boolean>(false);
  const [serieBookData, setSerieBookData] = useState<any>([]);
  const [serieBookRI, setSerieBookRI] = useState<number>(0);

  const [eventVisible, setEventVisible] = useState<boolean>(false);
  const [eventSearchValue, setEventSearchValue] = useState<string>('');
  const [eventLoading, setEventLoading] = useState<boolean>(false);
  const [eventLoaded, setEventLoaded] = useState<boolean>(false);
  const [eventError, setEventError] = useState<boolean>(false);
  const [eventData, setEventData] = useState<any>([]);
  const [eventRI, setEventRI] = useState<number>(0);

  const [serieVideoVisible, setSerieVideoVisible] = useState<boolean>(false);
  const [serieVideoSearchValue, setSerieVideoSearchValue] =
    useState<string>('');
  const [serieVideoLoading, setSerieVideoLoading] = useState<boolean>(false);
  const [serieVideoLoaded, setSerieVideoLoaded] = useState<boolean>(false);
  const [serieVideoError, setSerieVideoError] = useState<boolean>(false);
  const [serieVideoData, setSerieVideoData] = useState<any>([]);
  const [serieVideoRI, setSerieVideoRI] = useState<number>(0);
  const clearFilters = () => {
    setOrder('date:desc');
    setAdvancedData([
      { type: 'AND', text: '', exact: false, searchInTitle: false }
    ]);
    setDateData([]);
    setAreaData([]);
    setAuthorData([]);
    setEventData([]);
    setSerieVideoData([]);
    setPage(1);
  };

  const updateData = () => setSubmit(submit + 1);

  const updateDataAI = () => setSubmitAI(submitAI + 1);

  return (
    <GlobalFiltersContext.Provider
      value={{
        aiSearchText,
        setAiSearchText,
        submitAI,
        updateDataAI,
        loadingAI,
        setLoadingAI,
        aiChat,
        setAIChat,
        lastAIResult,
        setLastAIResult,

        searchType,
        setSearchType,

        totalGlobal,
        setTotalGlobal,
        isInSearchGlobal,
        setIsInSearchGlobal,
        loadingGlobal,
        setLoadingGlobal,

        submit,
        updateData,

        order,
        setOrder,

        page,
        setPage,

        advancedData,
        advancedVisible,
        setAdvancedData,
        setAdvancedVisible,

        dateData,
        dateError,
        dateLoaded,
        dateLoading,
        dateRI,
        dateVisible,
        setDateData,
        setDateError,
        setDateLoaded,
        setDateLoading,
        setDateRI,
        setDateVisible,

        typeData,
        typeError,
        typeLoaded,
        typeLoading,
        typeRI,
        typeSearchValue,
        typeVisible,
        setTypeData,
        setTypeError,
        setTypeLoaded,
        setTypeLoading,
        setTypeRI,
        setTypeSearchValue,
        setTypeVisible,

        areaData,
        areaError,
        areaLoaded,
        areaLoading,
        areaRI,
        areaSearchValue,
        areaVisible,
        setAreaData,
        setAreaError,
        setAreaLoaded,
        setAreaLoading,
        setAreaRI,
        setAreaSearchValue,
        setAreaVisible,

        authorData,
        authorError,
        authorLoaded,
        authorLoading,
        authorRI,
        authorSearchValue,
        authorVisible,
        setAuthorData,
        setAuthorError,
        setAuthorLoaded,
        setAuthorLoading,
        setAuthorRI,
        setAuthorSearchValue,
        setAuthorVisible,

        periodicData,
        periodicError,
        periodicLoaded,
        periodicLoading,
        periodicRI,
        periodicSearchValue,
        periodicVisible,
        setPeriodicData,
        setPeriodicError,
        setPeriodicLoaded,
        setPeriodicLoading,
        setPeriodicRI,
        setPeriodicSearchValue,
        setPeriodicVisible,

        appTypeVisible,
        appTypeSearchValue,
        appTypeData,
        setAppTypeVisible,
        setAppTypeSearchValue,
        setAppTypeData,

        bookData,
        bookError,
        bookLoaded,
        bookLoading,
        bookRI,
        bookSearchValue,
        bookVisible,
        setBookData,
        setBookError,
        setBookLoaded,
        setBookLoading,
        setBookRI,
        setBookSearchValue,
        setBookVisible,

        serieBookData,
        serieBookError,
        serieBookLoaded,
        serieBookLoading,
        serieBookRI,
        serieBookSearchValue,
        serieBookVisible,
        setSerieBookData,
        setSerieBookError,
        setSerieBookLoaded,
        setSerieBookLoading,
        setSerieBookRI,
        setSerieBookSearchValue,
        setSerieBookVisible,

        eventData,
        eventError,
        eventLoaded,
        eventLoading,
        eventRI,
        eventSearchValue,
        eventVisible,
        setEventData,
        setEventError,
        setEventLoaded,
        setEventLoading,
        setEventRI,
        setEventSearchValue,
        setEventVisible,

        serieVideoData,
        serieVideoError,
        serieVideoLoaded,
        serieVideoLoading,
        serieVideoRI,
        serieVideoSearchValue,
        serieVideoVisible,
        setSerieVideoData,
        setSerieVideoError,
        setSerieVideoLoaded,
        setSerieVideoLoading,
        setSerieVideoRI,
        setSerieVideoSearchValue,
        setSerieVideoVisible,
        clearFilters
      }}>
      {children}
    </GlobalFiltersContext.Provider>
  );
};

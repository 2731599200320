import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Divider, Row, Spin, Pagination as PaginationAntd } from 'antd';
import { AxiosResponse } from 'axios';
import { EffectCoverflow, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSearchFilter } from '../../../pesquisa/hooks/useSearchFilter';
import { Card as CardGlobal } from './card';
import { Card as CardBook } from '../contentListBook/card';
import { Card as CardPeriodic } from '../contentListPeriodic/card';
import { Card as CardVideo } from '../contentListVideo/card';
import { Container } from './styles';
import { Occurrences } from './types/occurrences';
import { NavbarContext } from '../../../../components/_shell/NavbarFilters/NavbarContext';
import { ListGlobal as List } from '../../global/components/ListGlobal';
import { SearchResults, Separator } from '../../_shared/styles';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { GlobalFiltersContext } from '../../home/GlobalFiltersContext';
import { Pager } from '../../../../components/v2/searchHelpers';
import { useLocation } from 'react-router';
import { getAIGlobal } from './services/getAIGlobal';
import moment from 'moment';

interface IParams {
  isAIChat?: boolean;
  aiChatFilters: any;
  searchAIPrompt?: string;
}

export const ContentListGlobalAI = ({
  isAIChat,
  aiChatFilters,
  searchAIPrompt
}: IParams) => {
  const [results, setResults] = useState<any>([]);
  const location = useLocation();
  const currentRoute = location.pathname;

  const resultsMemo = useMemo(() => results, [results]);

  const [loading, isLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const defaultPager = { currentPage: 0, pages: 0, perPage: 0, total: 0 };
  const [pager, setPager] = useState<Pager>(defaultPager);
  const { filter } = useSearchFilter();
  const { searchScope } = useContext(NavbarContext);
  const {
    typeData,
    areaData,
    bookData,
    serieBookData,
    periodicData,
    eventData,
    serieVideoData,
    appTypeData,
    authorData,
    dateData,
    submit,
    advancedData,
    order,
    page,
    loadingAI,
    setPage,
    setOrder,

    setTotalGlobal: setTotal,
    setIsInSearchGlobal: setIsInSearch,
    setLoadingGlobal: setLoading
  } = useContext(GlobalFiltersContext);

  const getGlobalAuthor = (global: any) => {
    let result = '';

    if (global.entity && 'authors' in global.entity) {
      result = global.entity.authors.map((el: any) => el.title).join(', ');
    }

    if (result.trim()) return result;

    return global.tags
      ? global.tags
          .filter((el: any) => String(el.id).startsWith('author_'))
          .map((el: any) => el.title.trim())
          .join(', ')
      : '';
  };

  const contentTypes = {
    book: 'livro',
    periodic: 'revista',
    informative: 'informativo/jacoby',
    video: 'videos'
  };

  const getLinkByContentType = (el: any) => {
    if (el.type === 'informative') {
      return `/v2/${contentTypes[el.type]}/${el.publish_date.split('T')[0]}`;
    }
    if (el.type === 'video') {
      return `/conteudo/videos/${String(el.id).replaceAll('V', '')}`;
    }
    return `/v2/${contentTypes[el.type]}/${String(el.id).replace('-', '/')}`;
  };

  const mapListGlobal = (el) => ({
    id: el.type === 'video' ? String(el.id).replaceAll('V', '') : el.id,
    type: el.type,
    edition_number: el.type === 'periodic' ? el.title : el.edition_number,
    title: el.type === 'periodic' ? el.parent_title_1 : el.title,
    date: el.publish_date ? el.publish_date.split('T')[0] : '',
    start_month: el.start_month,
    end_month: el.end_month,
    start_year: el.start_year,
    desc: el.description,
    link: getLinkByContentType(el),
    author: getGlobalAuthor(el),
    contents: el.contents,
    highlightContents: el.highlight_contents,
    cover:
      el.type === 'video'
        ? `videos/thumbs/${String(el.id).replaceAll('V', '')}-1.jpg`
        : ''
  });

  const fetchData = async () => {
    if (!Object.keys(aiChatFilters).length) {
      return isLoading(false);
    }
    if (aiChatFilters && aiChatFilters.page && Number(aiChatFilters.page) > 1) {
      setPage(aiChatFilters.page);
    }
    if (aiChatFilters && aiChatFilters.order) {
      setOrder(aiChatFilters.order);
    }

    const data = {
      order: aiChatFilters && aiChatFilters.order ? aiChatFilters.order : order,
      page:
        aiChatFilters && aiChatFilters.page && Number(aiChatFilters.page) > 1
          ? aiChatFilters.page
          : page,
      pageSize: aiChatFilters && aiChatFilters.size ? aiChatFilters.size : 10,
      size: aiChatFilters && aiChatFilters.size ? aiChatFilters.size : 10,
      filter: filter.words,
      searchScope
    };

    const advanced =
      aiChatFilters && aiChatFilters.searchBoolean
        ? aiChatFilters.searchBoolean
        : [];

    const dateStart =
      aiChatFilters && aiChatFilters.initialDate
        ? [aiChatFilters.initialDate]
        : [];
    const dateEnd =
      aiChatFilters && aiChatFilters.finalDate ? [aiChatFilters.finalDate] : [];
    const area =
      aiChatFilters && aiChatFilters.areas && aiChatFilters.areas.length
        ? aiChatFilters.areas.map((e) => e.id)
        : [];
    const book =
      aiChatFilters && aiChatFilters.books && aiChatFilters.books.length
        ? aiChatFilters.books.map((e) => e.id)
        : [];
    const serieBook =
      aiChatFilters &&
      aiChatFilters.book_series &&
      aiChatFilters.book_series.length
        ? aiChatFilters.book_series.map((e) => e.id)
        : [];
    const serieVideo =
      aiChatFilters &&
      aiChatFilters.video_series &&
      aiChatFilters.video_series.length
        ? aiChatFilters.video_series.map((e) => e.id)
        : [];
    const periodic =
      aiChatFilters && aiChatFilters.periodics && aiChatFilters.periodics.length
        ? aiChatFilters.periodics.map((e) => e.id)
        : [];
    const event =
      aiChatFilters && aiChatFilters.events && aiChatFilters.events.length
        ? aiChatFilters.events.map((e) => e.id)
        : [];
    const author =
      aiChatFilters && aiChatFilters.authors && aiChatFilters.authors.length
        ? aiChatFilters.authors.map((e) => e.id)
        : [];
    const articleTypes = [];
    if (aiChatFilters && aiChatFilters.article_types) {
      for (let a of aiChatFilters.article_types) {
        if (a === 'Doutrina') {
          articleTypes.push('doctrine');
        }
        if (a === 'Jurisprudência') {
          articleTypes.push('jurisprudence');
        }
      }
    }
    const appType = articleTypes;
    const dateStartParam = dateStart[0]
      ? moment(dateStart[0]).format('YYYY')
      : null;
    const dateEndParam = dateEnd[0] ? moment(dateEnd[0]).format('YYYY') : null;
    let type =
      aiChatFilters && aiChatFilters.types
        ? aiChatFilters.types
        : ['book', 'periodic', 'video', 'informative'];

    const filters: any = {
      advanced,
      area,
      book,
      serie: [...serieBook, ...serieVideo],
      periodic,
      appType,
      event,
      type,
      author,
      date: [dateStartParam, dateEndParam]
    };

    if (['/conteudo/livros'].includes(currentRoute)) {
      type = ['book'];
      filters.type = type;
      filters.serie = serieBook;
      filters.book = book;
    }
    if (['/conteudo/revistas'].includes(currentRoute)) {
      type = ['periodic'];
      filters.type = type;
      filters.periodic = periodic;
      filters.appType = appType;
    }
    if (['/conteudo/videos'].includes(currentRoute)) {
      type = ['video'];
      filters.type = type;
      filters.serie = serieVideo;
      filters.event = event;
    }
    if (['/conteudo/informativos'].includes(currentRoute)) {
      type = ['informative'];
      filters.type = type;
    }

    const response: AxiosResponse<Occurrences> = await getAIGlobal(
      data,
      filters,
      isAIChat,
      searchAIPrompt
    );

    verifyIsInSearch();
    setResults(response.data.hits);
    setPager({
      currentPage: response.data.currentPage || 0,
      pages: response.data.pages || 0,
      perPage: response.data.perPage || 0,
      total: response.data.total || 0
    });
    if (setTotal) setTotal(response.data.total || 0);
    setStatus(response.status);
    isLoading(false);
  };

  const verifyIsInSearch = () => {
    if (
      ['/conteudo/livros', '/conteudo/revistas', '/conteudo/videos'].includes(
        currentRoute
      ) &&
      setIsInSearch &&
      setIsSearch
    ) {
      setIsInSearch(true);
      setIsSearch(true);
      return;
    }
    const advanced = advancedData.filter((e) => e.text);
    const dateStart = dateData.filter((e) => e.start);
    const dateEnd = dateData.filter((e) => e.end);
    const area = areaData.filter((e) => e.checked).map((e) => e.key);
    const author = authorData.filter((e) => e.checked).map((e) => e.key);
    const dateStartParam = dateStart[0] ? dateStart[0].value : null;
    const dateEndParam = dateEnd[0] ? dateEnd[0].value : null;
    if (setIsInSearch) {
      if (
        `${filter.words}`.trim() !== '' ||
        area.length ||
        author.length ||
        advanced.length
      ) {
        setIsInSearch(true);
        setIsSearch(true);
      } else if (dateStartParam && dateEndParam) {
        setIsInSearch(true);
        setIsSearch(true);
      } else {
        setIsInSearch(false);
        setIsSearch(false);
      }
    }
  };

  useEffect(() => {
    verifyIsInSearch();
    isLoading(true);
    fetchData();
  }, [searchScope, filter.words, submit, page, currentRoute, aiChatFilters]);

  useEffect(() => {
    if (!Object.keys(aiChatFilters).length) {
      setResults([]);
      setStatus(0);
    }
  }, [aiChatFilters]);

  useEffect(() => {
    if (setLoading) setLoading(loading);
  }, [loading]);

  return (
    <Container
      id="element-7"
      style={
        isAIChat
          ? { maxHeight: 484, overflowY: 'scroll', overflowX: 'hidden' }
          : {}
      }>
      {isSearch || isAIChat ? (
        <>
          <div className="title">
            <h2></h2>
          </div>
          <Separator>
            <div className="sep">
              <span className="sep-inner" />
            </div>
          </Separator>
        </>
      ) : null}
      {(loading || loadingAI) && (
        <Spin size="large" style={{ marginTop: '200px' }} />
      )}
      {!(loading || loadingAI) && results.length === 0 && status === 200 && (
        <div className="message-wrapper">
          <p>Nenhum resultado encontrado.</p>
        </div>
      )}
      {!(loading || loadingAI) &&
        status !== 200 &&
        !Object.keys(aiChatFilters).length && (
          <p className="message-wrapper">
            Os dados serão mostrados quando o chat for iniciado.
          </p>
        )}
      {!(loading || loadingAI) &&
        status !== 200 &&
        !!Object.keys(aiChatFilters).length && (
          <p className="message-wrapper">Houve um erro ao carregar os dados.</p>
        )}
      {!(loading || loadingAI) &&
        results.length > 0 &&
        (isSearch || isAIChat ? (
          <div
            style={
              isAIChat &&
              !(
                aiChatFilters &&
                aiChatFilters.search &&
                aiChatFilters.search.length
              )
                ? { marginLeft: -105 }
                : {}
            }>
            <SearchResults>
              <List items={results} isAIChat={isAIChat} />
            </SearchResults>
            <Row type="flex" justify="center">
              <Col>
                <Divider />
                <PaginationAntd
                  current={pager.currentPage}
                  pageSize={pager.perPage}
                  total={pager.total}
                  onChange={setPage}
                />
                <br />
              </Col>
            </Row>
            <br />
          </div>
        ) : (
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={30}
            pagination={{
              clickable: true,
              type: 'progressbar'
            }}
            navigation={true}
            modules={[Pagination, Navigation, EffectCoverflow]}
            className="swiper">
            {resultsMemo.map(mapListGlobal).map((el: any, i: number) => (
              <SwiperSlide key={i}>
                {el.type === 'book' && (
                  <CardBook
                    id={el.id}
                    edition={el.edition_number}
                    title={el.title}
                    date={el.date}
                    author={el.author}
                    desc={el.desc}
                    link={el.link}
                    contents={el.contents}
                    highlights={el.highlightContents}
                  />
                )}
                {el.type === 'periodic' && (
                  <CardPeriodic
                    id={el.id}
                    title={el.title}
                    desc={el.desc}
                    link={el.link}
                    contents={el.contents}
                    highlights={el.highlightContents}
                    number={String(el.edition_number)}
                    startMonth={el.start_month}
                    endMonth={el.end_month}
                  />
                )}
                {el.type === 'video' && (
                  <CardVideo
                    key={el.id}
                    id={el.id}
                    title={el.title}
                    cover={el.cover}
                    date={el.date}
                    author={el.author}
                    desc={el.desc}
                    link={el.link}
                  />
                )}
                {el.type === 'informative' && (
                  <CardGlobal
                    id={el.id}
                    type={el.type}
                    edition={el.edition_number}
                    title={el.title}
                    date={el.date}
                    author={el.author}
                    desc={el.desc}
                    link={el.link}
                    contents={el.contents}
                    highlights={el.highlightContents}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        ))}
    </Container>
  );
};

import { ProductType } from '../../../enums/ProductType';

export function useConvertContractedTags(
  contractedTags: { id: string; title: string }[]
) {
  const arrayTypes: number[] = [];

  const all_platform = contractedTags.filter((c) =>
    String(c.id).includes('platform-all')
  );
  if (all_platform.length) {
    arrayTypes.push(ProductType.Periodics);
    arrayTypes.push(ProductType.Books);
    arrayTypes.push(ProductType.Videos);
    arrayTypes.push(ProductType.Codes);
    arrayTypes.push(ProductType.Clipping);
  }

  const periodics = contractedTags.filter((c) =>
    String(c.id).startsWith('periodic')
  );
  if (periodics.length) arrayTypes.push(ProductType.Periodics);

  const books = contractedTags.filter(
    (c) =>
      String(c.id).startsWith('book-serie') || String(c.id).startsWith('book_')
  );
  if (books.length) arrayTypes.push(ProductType.Books);

  const videos = contractedTags.filter((c) =>
    String(c.id).startsWith('video-serie')
  );
  if (videos.length) arrayTypes.push(ProductType.Videos);

  const code = contractedTags.filter((c) => String(c.id).startsWith('code'));
  if (code.length) arrayTypes.push(ProductType.Codes);

  const news = contractedTags.filter((c) => String(c.id).startsWith('news'));
  if (news.length) arrayTypes.push(ProductType.Clipping);

  const uniqueArray = Array.from(new Set(arrayTypes));

  return uniqueArray;
}

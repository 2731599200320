import React from 'react';
import { Badge, Button, Checkbox, Icon, Input, Popover, Tag } from 'antd';
import { GlobalNavBarContainer, GlobalNavBarResults } from '../../../styles';

export const FixedData = ({
  placeholder,
  visible,
  searchValue,
  data,
  setVisible,
  setSearchValue,
  setData,
  updateData,
  setPage,
  filterable
}) => {
  const { Search } = Input;

  const onChangeSearch = (ev) => {
    const val = ev.target.value || '';
    setSearchValue(val);
    setPage(1);
  };

  const onChangeCheckbox = (checked, item) => {
    const index = data.findIndex((e) => e.key === item.key);
    const upData = [...data];
    upData[index] = { ...item, checked };
    setData(upData);
    setPage(1);
    updateData();
  };

  const currentData = data || [];
  const selecteds = currentData.filter((e) => e.checked) || [];
  const searchResults = currentData.filter((e) =>
    e.value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .includes(
        searchValue
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      )
  );

  const results = searchResults.map((d, i) => (
    <Checkbox
      key={i}
      checked={d.checked}
      onChange={(ev) => onChangeCheckbox(ev.target.checked, d)}>
      {d.value}
    </Checkbox>
  ));

  const tags = currentData.map((d, i) => (
    <Tag
      key={i}
      closable
      visible={d.checked}
      onClose={() => onChangeCheckbox(false, d)}>
      {d.value}
    </Tag>
  ));

  const content = (
    <GlobalNavBarResults>
      {selecteds.length ? <div className="selecteds">{tags}</div> : null}
      {filterable ? (
        <Search
          autoFocus
          className="filter-search"
          placeholder={`Buscar ${placeholder.toLowerCase()}`}
          value={searchValue}
          onChange={onChangeSearch}
        />
      ) : null}
      <div className="results">{results}</div>
    </GlobalNavBarResults>
  );

  return (
    <GlobalNavBarContainer>
      <Popover
        trigger="click"
        placement="bottom"
        visible={visible}
        content={content}
        onVisibleChange={() => setVisible(!visible)}
        getPopupContainer={(trigger) => trigger}>
        <Button className="filter-btn">
          {placeholder} <Icon type="down" />
          <Badge count={selecteds.length} />
        </Button>
      </Popover>
    </GlobalNavBarContainer>
  );
};

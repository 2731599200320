import React, { useContext } from 'react';

import { GlobalFiltersContext } from '../../GlobalFiltersContext';
import { OneTimeFetch } from './use-cases/OneTimeFetch';

export const VideoSerieFilter = ({}) => {
  const {
    loadingGlobal,
    serieVideoVisible,
    serieVideoSearchValue,
    serieVideoLoading,
    serieVideoLoaded,
    serieVideoError,
    serieVideoData,
    serieVideoRI,
    setSerieVideoVisible,
    setSerieVideoSearchValue,
    setSerieVideoLoading,
    setSerieVideoLoaded,
    setSerieVideoError,
    setSerieVideoData,
    setSerieVideoRI,
    updateData,
    setPage
  } = useContext(GlobalFiltersContext);

  return (
    <OneTimeFetch
      segment="video-serie"
      placeholder="Séries"
      loadingGlobal={loadingGlobal}
      visible={serieVideoVisible}
      searchValue={serieVideoSearchValue}
      loading={serieVideoLoading}
      loaded={serieVideoLoaded}
      error={serieVideoError}
      data={serieVideoData}
      ri={serieVideoRI}
      setVisible={setSerieVideoVisible}
      setSearchValue={setSerieVideoSearchValue}
      setLoading={setSerieVideoLoading}
      setLoaded={setSerieVideoLoaded}
      setError={setSerieVideoError}
      setData={setSerieVideoData}
      setRI={setSerieVideoRI}
      updateData={updateData}
      setPage={setPage}
    />
  );
};

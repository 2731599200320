import { Col, Icon, Input } from 'antd';
import styled from 'styled-components';

export const ColIA = styled(Col)`
  text-align: center;
  padding-top: 20px;
  padding-left: 25px;
  min-height: 560px;
  border: 2px solid #f3f4f6;
  border-radius: 20px 0 0 20px;
`;

export const ColResult = styled(Col)`
  border: 2px solid #f3f4f6;
  border-radius: 0 20px 20px 0;
  padding: 20px;
  min-height: 560px;
  padding-bottom: 0;
`;

export const HrResult = styled.hr`
  margin-top: 20px;
  border: 0;
  border-top: 2px solid #f3f4f6;
`;

export const IAContainer = styled.div`
  display: flex;
  padding-top: 10px;
  border-top: 1px solid #f3f4f6;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
  right: 0;
  background: #fff;
  border-radius: 0px 0 0 20px;
  .ant-input:focus {
    -webkit-box-shadow: unset;
    box-shadow: unset;
    border-color: unset;
  }
  .ant-input:hover {
    border-color: unset;
  }
`;

export const IAContainerHelp = styled.div`
  margin-top: 0;
`;

export const SearchBarContainer = styled.div`
  display: flex;
`;

export const SearchContainer = styled.div`
  background: #f3f4f6;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #0051d6;
`;

export const SearchInput = styled(Input)`
  width: 445px;
  border: 0;
  border-radius: 0;
  padding-top: 5px;
  padding-bottom: 6px;
  background: #f3f4f6;
`;

export const IAIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-top: 8px;
  margin-right: 5px;
`;

export const IconSearch = styled.img`
  width: 20px;
  margin: 0 0 0 5px;
`;

export const IconClear = styled(Icon)`
  font-size: 16px;
  margin-top: 8px;
`;

export const SearchButton = styled.button`
  width: 42px;
  height: 42px;
  background: #0051d6;
  border-radius: 5px;
  margin-left: 5px;
  color: #fff;
  border: none;
  margin-top: 1px;
`;

export const ClearContainer = styled.div`
  cursor: pointer;
  margin-left: 10px;
  margin-top: 5px;
`;

export const ClearAllContainer = styled.div`
  display: block;
  float: right;
  width: 204px;
  height: 32px;
  border: 2px solid #f3f4f6;
  border-radius: 2px;
  font-size: 11px;
  padding: 5px;
  padding-left: 6px;
  margin-top: 10px;
  margin-right: 130px;
  cursor: pointer;
`;

export const ClearSpan = styled.span`
  font-size: 12px;
  color: #323232;
  font-weight: bold;
  cursor: pointer;
`;

export const IAChatContainer = styled.div`
  text-align: left;
  padding-left: 40px;
  padding-top: 20px;
  padding-bottom: 10px;
  max-height: 460px;
  overflow-y: scroll;
  padding-bottom: 55px;
`;

export const ChatSpan = styled.span`
  width: 80px;
  background: #f3f4f6;
  border-radius: 5px;
  font-size: 10px;
  padding: 8px;
  padding-top: 6px;
  font-weight: bold;
  margin-left: -20px;
`;

export const ChatSpanRight = styled.span`
  width: 44px;
  margin-right: 50px;
  background: #f3f4f6;
  border-radius: 5px;
  font-size: 10px;
  padding: 8px;
  padding-top: 6px;
  font-weight: bold;
  float: right;
  margin-top: 5px;
`;

export const IconMessage = styled.img`
  display: block;
  width: 35px;
  margin: 10px 0 0 5px;
`;

export const IconMessageRight = styled.img`
  display: block;
  width: 35px;
  margin: 10px 0 0 5px;
  float: right;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;

export const IconRight = styled(Icon)`
  display: inline;
  float: right;
  margin-right: 70px;
  font-size: 25px;
  margin-top: -45px;
`;

export const MessageContainer = styled.div`
  background: #f4f4f4;
  border-radius: 20px;
  padding: 10px;
  padding-left: 15px;
  margin-top: -28px;
  max-width: 480px;
  margin-left: 45px;
`;

export const MessageContainerRight = styled.div`
  background: #f4f4f4;
  border-radius: 20px;
  padding: 10px;
  padding-left: 15px;
  margin-top: 28px;
  max-width: 480px;
  margin-left: 75px;
`;

export const RefineOneContainer = styled.div`
  background: #e4e9f0;
  border-radius: 10px;
  padding: 7px;
  padding-left: 12px;
  max-width: 314px;
  margin-left: 55px;
  margin-top: 10px;
  font-size: 12px;
`;

export const RefineTwoContainer = styled.div`
  border-radius: 10px;
  padding: 7px;
  padding-left: 12px;
  max-width: 314px;
  margin-left: 100px;
  margin-top: 10px;
  font-size: 12px;
`;

export const RefineThreeContainer = styled.div`
  border: 2px dashed #f4f4f4;
  border-radius: 10px;
  padding: 7px;
  padding-left: 12px;
  max-width: 314px;
  margin-left: -25px;
  margin-top: 10px;
  font-size: 12px;
`;

export const IconForm = styled(Icon)`
  font-size: 16px;
  margin-left: 5px;
  vertical-align: middle;
`;

export const LogoSmall = styled.img`
  background: #e5e5e5;
  width: 70px;
  padding: 2px;
  border-radius: 4px;
`;

export const FilterBaseText = styled.div`
  font-size: 13px;
  padding-left: 5px;
  margin-top: 5px;
  line-height: normal;
`;

export const HeaderResults = styled.div`
  width: 100%;
  text-align: center;
`;

import React from 'react';

import {
  IAContainer,
  IAContainerHelp,
  IAIcon
} from '../../../pages/v2/global/styles';
import { IASearchBar } from '../../../pages/v2/global/IASearchBar';

export const NavbarSearchIA = ({
  helpLabel = false
}: {
  helpLabel?: boolean;
}) => {
  return (
    <>
      {helpLabel ? (
        <IAContainerHelp>
          <h3>Vamos começar?</h3>
        </IAContainerHelp>
      ) : (
        <></>
      )}
      <IAContainer>
        <div style={{ margin: '0 auto' }}>
          <IASearchBar />
        </div>
      </IAContainer>
    </>
  );
};

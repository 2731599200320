export const getInformativeCover = (title: string) => {
  const canvas = document.createElement('canvas');
  canvas.width = 160 * 4;
  canvas.height = 220 * 4;
  const context = canvas.getContext('2d')!;
  context.rect(0, 0, 160 * 4, 220 * 4);
  context.fillStyle = '#024D92';
  context.fill();
  context.font = 'bold 65px "Segoe UI", sans-serif';
  context.fillStyle = 'white';
  const textSplited = String(title).split(': ');
  context.fillText(textSplited[0], 6 * 4, 110 * 4);
  context.fillText(textSplited[1], 40 * 4, 140 * 4);
  return canvas.toDataURL('image/png');
};

import React from 'react';
import { Book } from '../../interfaces/Book';

import VoiceIcon from '../../../../../assets/images/icon-voice.svg';
import { BookInfo, BookInnerFields, BookPopoverContent } from '../../styles';
import { Button, Icon, Popover } from 'antd';

export const Author = ({ item }) => {
  const author = (el: Book) =>
    el.tags
      .filter((e) => String(e.id).includes('author'))
      .map((e) => e.title)
      .join(', ');
  const authors = author(item);

  const contentPopover = (
    <div>
      <BookPopoverContent>{authors}</BookPopoverContent>
    </div>
  );

  return authors ? (
    <Popover
      trigger="click"
      placement="bottom"
      content={contentPopover}
      getPopupContainer={(triggerNode) => triggerNode}>
      <BookInnerFields>
        <BookInfo className="info-authors">
          <img src={VoiceIcon} />
          <span>
            {authors.length > 150 ? `${authors.slice(0, 150)}...` : authors}
          </span>
          <div>
            <Button size="small" color="primary" style={{ marginLeft: 5 }}>
              <Icon type={'eye'} style={{ fontSize: 14, cursor: 'pointer' }} />
            </Button>
          </div>
        </BookInfo>
      </BookInnerFields>
    </Popover>
  ) : (
    <></>
  );
};

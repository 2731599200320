import { Tag as AntTag } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const Tag = styled(AntTag)`
  font-family: var(--title-font-family);
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 0.7rem;
  margin-bottom: 10px;
`;

interface IProps {
  type: string;
}

export const ContentTypeTagGlobal = ({ type }: IProps) => {
  const colors = {
    revista: '#c0392b',
    livro: '#4834d4',
    video: '#eb4d4b',
    informativo: '#16a085'
  };

  const texts = {
    livro: 'Livro',
    revista: 'Revista',
    video: 'Vídeo',
    informativo: 'Informativo'
  };

  return (
    <Tag color={colors[`${type}`.trim().toLocaleLowerCase()]}>
      {texts[`${type}`.trim().toLocaleLowerCase()]}
    </Tag>
  );
};

import styled from 'styled-components';
import { device } from '../../styledComponent';

export const Container = styled.div`
  position: relative;
  z-index: 12;
  backdrop-filter: saturate(180%) blur(10px);
  background: #003a70;
  @media (max-width: 639px) {
    min-height: 250px;
  }
`;

export const Navbar = styled.nav`
  padding-top: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid #9aa6b4;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  transition: all ease-in-out 1s;
  margin-left: 16px;
  margin-right: 16px;

  @media ${device.sm} {
  }
`;

export const Menus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin-left: 16px;
  margin-right: 16px;
`;

export const Link = styled.a`
  color: black !important;
`;

export const ContainerMobile = styled.div`
  width: 100%;
  height: 150px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const ContainerMobileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ContainerLogo = styled.div`
  width: 185px;
  border-right: 1px solid #9aa6b4;
`;

export const ContentOptions = styled.div`
  height: 50px;
  padding-top: 12px;
  padding-left: 30px;

  .ant-radio-inner {
    background-color: transparent;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #fff;
  }
  .ant-radio-inner:after {
    background-color: #fff;
  }
`;

import React, { useContext } from 'react';
import { ContentTypeName } from '../../../pages/pesquisa/enums/ContentTypeName';
import { UserSession } from '../../../models/auth/UserSession';
import { ProductType } from '../../../enums/ProductType';
import { withRouter } from 'react-router';
import { useSearchFilter } from '../../../pages/pesquisa/hooks/useSearchFilter';
import { useContractedProducts } from '../../../Hooks/useContractedProducts';
import { Container, TabPane, Tabs, TabsWait } from './styles';
import { useQuery } from '../../../pages/v2/_shared/useQuery';
import { GlobalFiltersContext } from '../../../pages/v2/home/GlobalFiltersContext';

export const contentSelectionHeight = 0;

const menuByProductType = [
  {
    type: ProductType.Books,
    menu: {
      name: ContentTypeName.BOOK,
      text: 'Livros',
      value: ContentTypeName.BOOK
    }
  },
  {
    type: ProductType.Periodics,
    menu: {
      name: ContentTypeName.PERIODIC,
      text: 'Revistas',
      value: ContentTypeName.PERIODIC
    }
  },
  {
    type: ProductType.Videos,
    menu: {
      name: ContentTypeName.VIDEO,
      text: 'Vídeos',
      value: ContentTypeName.VIDEO
    }
  },
  {
    type: ProductType.Clipping,
    menu: {
      name: ContentTypeName.NEWSLETTER,
      text: 'Informativos',
      value: ContentTypeName.NEWSLETTER
    }
  },
  {
    type: ProductType.Codes,

    menu: {
      name: ContentTypeName.CODE,
      text: 'Códigos',
      value: ContentTypeName.CODE
    }
  }
  // {
  // 	type: ProductType.Legislation,

  // 	menu: {
  // 		name: ContentTypeName.LEGISLATION,
  // 		text: "Legislação Comentada",
  // 		value: ContentTypeName.LEGISLATION,
  // 	},
  // },
];

interface IProps {
  match?: any;
  history?: any;
  user?: UserSession;
}

const TabContentSelection = ({ history, user }: IProps) => {
  const { hasAccessToProduct } = useContractedProducts();
  const { contentType, filter } = useSearchFilter();
  const { loadingGlobal, loadingAI } = useContext(GlobalFiltersContext);
  const wordsParam = useQuery().get('words');
  const searchParam = useQuery().get('search');
  const scopeParam = useQuery().get('searchScope');
  const searchString = searchParam || wordsParam;

  let menuOptions: any[] = [];

  menuByProductType.forEach((m) => {
    if (hasAccessToProduct(m.type)) {
      menuOptions.push(m.menu);
    }
  });

  menuOptions.unshift({
    text: 'Todos',
    value: ContentTypeName.ALL,
    name: ContentTypeName.ALL
  });

  const [currentSelectedOption] = menuOptions.filter(
    (o) => o.name === contentType
  );

  if (!user) return null;

  const action = (tabText: string) => {
    if (loadingGlobal || loadingAI) return;
    const {
      REACT_APP_NEW_BOOK_VERSION,
      REACT_APP_NEW_PERIODIC_VERSION,
      REACT_APP_NEW_CODES_VERSION,
      REACT_APP_NEW_INFORMATIVES_VERSION
    } = process.env;
    const newBookVersion = String(REACT_APP_NEW_BOOK_VERSION) === 'true';
    const newPeriodicVersion =
      String(REACT_APP_NEW_PERIODIC_VERSION) === 'true';
    const newCodesVersion = String(REACT_APP_NEW_CODES_VERSION) === 'true';
    const newInformativesVersion =
      String(REACT_APP_NEW_INFORMATIVES_VERSION) === 'true';
    const contentType = menuOptions.filter((o) => o.text === tabText)[0].name;

    const versionPage =
      contentType === ContentTypeName.VIDEO ||
      (contentType === ContentTypeName.BOOK && newBookVersion) ||
      (contentType === ContentTypeName.CODE && newCodesVersion) ||
      (contentType === ContentTypeName.NEWSLETTER && newInformativesVersion) ||
      (contentType === ContentTypeName.PERIODIC && newPeriodicVersion)
        ? 'conteudo'
        : 'pesquisa';

    let globalQueryString = '';

    if (
      contentType === ContentTypeName.ALL ||
      contentType === ContentTypeName.VIDEO ||
      (contentType === ContentTypeName.BOOK && newBookVersion) ||
      (contentType === ContentTypeName.CODE && newCodesVersion) ||
      (contentType === ContentTypeName.NEWSLETTER && newInformativesVersion) ||
      (contentType === ContentTypeName.PERIODIC && newPeriodicVersion)
    ) {
      globalQueryString += `v=2`;
      if (searchString) {
        const searchEncoded = encodeURIComponent(searchString.trim()).replace(
          '%20',
          '+'
        );
        globalQueryString += `&search=${searchEncoded}&searchScope=${
          scopeParam || '0'
        }`;
      }
    } else {
      if (searchString) filter.setWords(searchString);
      if (['0', '1'].includes(scopeParam)) {
        filter.searchScope = scopeParam || '0';
      }

      const newFilter = filter.eraseSpecificFilters();
      globalQueryString = newFilter.convertToQueryString();
    }

    history.push(`/${versionPage}/${contentType}?${globalQueryString}`);
  };

  const activeKey =
    contentType && currentSelectedOption
      ? menuOptions.filter((o) => o.name === contentType)[0].text
      : '';

  return (
    <Container
      id="element-6"
      style={loadingGlobal || loadingAI ? { cursor: 'wait' } : {}}>
      {loadingGlobal || loadingAI ? (
        <TabsWait>
          <Tabs
            size="small"
            activeKey={activeKey}
            onTabClick={action}
            animated={false}>
            {menuOptions.map((opt) => (
              <TabPane tab={opt.text} key={opt.text} />
            ))}
          </Tabs>
        </TabsWait>
      ) : (
        <Tabs
          size="small"
          activeKey={activeKey}
          onTabClick={action}
          animated={false}>
          {menuOptions.map((opt) => (
            <TabPane tab={opt.text} key={opt.text} />
          ))}
        </Tabs>
      )}
    </Container>
  );
};

export default withRouter(TabContentSelection);

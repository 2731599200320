export const redirects = [
  'bookRedirect',
  'videoRedirect',
  'periodicRedirectV2'
];

export const bookRoutesNotSearch = ['bookChapter', 'bookEdition', 'bookView'];
export const bookRoutes = [
  'bookList',
  'bookChapter',
  'bookEdition',
  'bookView'
];

export const videoRoutes = ['videoList', 'videoView'];
export const videoRoutesNotSearch = ['videoView'];

export const periodicRoutes = [
  'periodicList',
  'periodicArticle',
  'periodicEdition',
  'periodicView'
];
export const periodicRoutesNotSearch = [
  'periodicArticle',
  'periodicEdition',
  'periodicView'
];

export const periodicRoutesNotSearchV2 = [
  'periodicItemV2',
  'periodicEditionV2',
  'periodicViewV2'
];

export const periodicRoutesV2 = [
  'periodicListV2',
  'periodicItemV2',
  'periodicEditionV2',
  'periodicViewV2'
];

export const codesRoutesV2 = ['codesListV2'];
export const informativesRoutesV2 = ['informativesListV2'];

export const ignoreSearchOnFirstAccess = [
  'code',
  'clipping',
  ...redirects,
  ...videoRoutesNotSearch,
  ...bookRoutesNotSearch,
  ...periodicRoutesNotSearch
];

export const searchStringForParam = (searchString: string) =>
  encodeURIComponent(searchString).replace('%20', '+');

export const goTo = (history, url: string) => history.push(url);

export const getActivePath = (path: string) => {
  const paths = {
    videoRedirect: /^\/video\/?$/gi,
    videoView: /^\/conteudo\/videos\/\d+\/?$/gi,
    videoList: /^\/conteudo\/videos\/?$/gi,

    bookRedirect: /^\/livro\/?$/gi,
    bookChapter: /^\/v2\/livro\/L\d+\/E\d+\/\d+\/?$/gi,
    bookEdition: /^\/v2\/livro\/L\d+\/E\d+\/?$/gi,
    bookView: /^\/v2\/livro\/L\d+\/?$/gi,
    bookList: /^\/conteudo\/livros\/?$/gi,

    periodicRedirectV2: /^\/periodico\/?$/gi,
    periodicItemV2: /^\/v2\/revista\/P\d+\/E\d+\/\d+\/?$/gi,
    periodicEditionV2: /^\/v2\/revista\/P\d+\/E\d+\/?$/gi,
    periodicViewV2: /^\/v2\/revista\/P\d+\/?$/gi,
    periodicListV2: /^\/conteudo\/revistas\/?$/gi,

    periodicArticle: /^\/periodico\/\d+\/\d+\/?$/gi,
    periodicEdition: /^\/periodico\/\d+\/?$/gi,
    periodicView: /^\/periodico\/?$/gi,
    periodicList: /^\/pesquisa\/revistas\/?$/gi,

    clipping: /^\/pesquisa\/informativos\/?$/gi,
    code: /^\/pesquisa\/codigos\/?$/gi,

    codesListV2: /^\/conteudo\/codigos\/?$/gi,
    informativesListV2: /^\/conteudo\/informativos\/?$/gi,

    search: /^\/pesquisa\/?$/gi
  };

  const activePath = Object.keys(paths).filter((key) => paths[key].test(path));
  return activePath.length ? activePath[0] : 'search';
};

export interface ISearchParams {
  [key: string]: string | number | boolean;
}

export interface Pager {
  currentPage: number;
  pages: number;
  perPage: number;
  total: number;
}

export type OrderType =
  | 'relevance'
  | 'id:asc'
  | 'id:desc'
  | 'date:asc'
  | 'date:desc';

export type SearchType = 'boolean' | 'ai';

export type OrderByOptionsInterface = {
  show: boolean;
  title: string;
  value: OrderType;
};

export const sortByOptions: OrderByOptionsInterface[] = [
  { show: false, value: 'relevance', title: 'Relevância' },
  { show: true, value: 'date:desc', title: 'Mais novo primeiro' },
  { show: true, value: 'date:asc', title: 'Mais antigo primeiro' }
];

export const sortByOptionsCode: OrderByOptionsInterface[] = [
  { show: true, value: 'relevance', title: 'Relevância' }
];

export const sortByOptionsInformative: OrderByOptionsInterface[] = [
  { show: true, value: 'relevance', title: 'Relevância' }
];

export const getValidSortBy = (
  sortBy: string,
  fallback: string = 'date:desc'
) => {
  const validSortBy = ['relevance', 'date:desc', 'date:asc'];
  const result = validSortBy.includes(sortBy) ? sortBy : fallback;
  return result as OrderType;
};

export const getUrlParamsForSearch = (path: string, params: ISearchParams) => {
  const urlParams = Object.keys(params).reduce(
    (acc, key) => `${acc}&${key}=${searchStringForParam(String(params[key]))}`,
    ''
  );

  return `${path}?${urlParams}`;
};

import React, { useContext, useEffect, useState } from 'react';
import LogoIcon from '../../../assets/images/logo-forum-sign-white.png';
import NavbarFilters from '../NavbarFilters';
import TabContentSelection from '../TabContentSelection';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { FooterLogoImg } from '../../globalStyles';
import { NavbarSearch } from '../NavbarSearch';
import { NavbarUserLogin } from '../NavbarUserLogin';
import { Container, ContainerLogo, ContentOptions, Navbar } from './styles';
import { SearchFilter } from '../../../models/search/SearchFilter';
import { useSearchFilter } from '../../../pages/pesquisa/hooks/useSearchFilter';
import { VideoFiltersContext } from '../../../pages/v2/video/VideoFiltersContext';
import { BookFiltersContext } from '../../../pages/v2/book/BookFiltersContext';
import { NavbarContext } from '../NavbarFilters/NavbarContext';
import { PeriodicFiltersContext } from '../../../pages/v2/periodic/PeriodicFiltersContext';
import { GlobalFiltersContext } from '../../../pages/v2/home/GlobalFiltersContext';
import { Icon, Radio } from 'antd';
import { SearchType } from '../../v2/searchHelpers';
import { useAuthCtx } from '../../../features/auth_v2/hooks/useAuthCtx';

export const topbarHeight = 0;

const Topbar = () => {
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const { session } = useAuthCtx();
  const client = session.client;

  let isInHomePage = false;
  let myLocation = '';
  let splittedUrl = [];

  const isInVideos = location.pathname.startsWith('/conteudo/videos');
  const pathname = String(location.pathname).split('/');
  const isInVideosView = isInVideos && pathname.length > 3;
  const isInRevistasV2 = location.pathname.startsWith('/conteudo/revistas');
  const isInCodesV2 = location.pathname.startsWith('/conteudo/codigos');
  const isInLivros = location.pathname.startsWith('/conteudo/livros');
  const isInRevistas = location.pathname.startsWith('/pesquisa/revista');
  const isInInformativo = location.pathname.startsWith('/pesquisa/informativo');
  const isInInformativoV2 = location.pathname.startsWith(
    '/conteudo/informativos'
  );
  const isInCodigos = location.pathname.startsWith('/pesquisa/codigos');
  const isInTodos = location.pathname.startsWith('/pesquisa/todos');
  const isInOutros =
    isInRevistas || isInInformativo || isInCodigos || isInTodos;

  const {
    areaData: areaDataGlobal,
    authorData: authorDataGlobal,
    dateData: dateDataGlobal,
    typeData: typeDataGlobal,
    setAreaData: setAreaDataGlobal,
    setTypeData: setTypeDataGlobal,
    setAuthorData: setAuthorDataGlobal,
    setAreaSearchValue: setAreaSearchValueGlobal,
    setAuthorSearchValue: setAuthorSearchValueGlobal,
    setDateData: setDateDataGlobal,
    setOrder: setOrderGlobal,

    setAdvancedData,
    updateData: updateDataGlobal,
    advancedData,
    submit,

    searchType,
    setSearchType,

    setLastAIResult,
    setLoadingAI,
    setAIChat,

    clearFilters
  } = useContext(GlobalFiltersContext);

  const isInSearch =
    ((isInVideos && !isInVideosView) ||
      isInRevistasV2 ||
      isInCodesV2 ||
      isInLivros ||
      isInInformativoV2 ||
      isInTodos) &&
    searchType !== 'ai';

  const { setSearchString, setSearchScope } = useContext(NavbarContext);
  const { contentType, changeFilter } = useSearchFilter();

  const firstAdvancedSearch = () => {
    const filters =
      advancedData && advancedData.filter
        ? advancedData.filter((e) => e.text.length > 0)
        : [];
    return filters.length > 0 ? filters[0].text : '';
  };

  useEffect(() => {
    const search = firstAdvancedSearch() || '';
    setIsSearch(!!search);
  }, [submit]);

  useEffect(() => {
    setLastAIResult({});
    setLoadingAI(false);
    setAIChat([]);
    clearFilters();
  }, [searchType]);

  useEffect(() => {
    setSearchType('boolean' as SearchType);
  }, [contentType]);

  const HomeLogoButtonVideo = () => {
    const history = useHistory();
    const {
      areaData,
      authorData,
      eventData,
      serieData,
      dateData,
      setDateData,
      setAreaData,
      setAuthorData,
      setEventData,
      setSerieData,
      updateData,
      setAreaSearchValue,
      setAuthorSearchValue,
      setEventSearchValue,
      setSerieSearchValue,
      setOrder
    } = useContext(VideoFiltersContext);

    const checkFalse = (e) => ({ ...e, checked: false });
    const checkRangeFalse = (e) => ({ ...e, start: false, end: false });

    const clearFiltersVideo = () => {
      setSearchType('boolean' as SearchType);
      setAreaSearchValue('');
      setAuthorSearchValue('');
      setEventSearchValue('');
      setSerieSearchValue('');
      setAdvancedData([{ type: 'AND', text: '', exact: false }]);
      setAreaData(areaData.map(checkFalse));
      setAuthorData(authorData.map(checkFalse));
      setEventData(eventData.map(checkFalse));
      setSerieData(serieData.map(checkFalse));
      setDateData(dateData.map(checkRangeFalse));
      setOrder('date:desc');
      updateData();
      setTimeout(() => {
        history.push(`/pesquisa/todos`);
      }, 200);
    };

    return <FooterLogoImg src={LogoIcon} onClick={clearFiltersVideo} />;
  };

  const HomeLogoButtonBook = () => {
    const history = useHistory();

    const {
      areaData,
      authorData,
      serieData,
      dateData,
      setAreaData,
      setAuthorData,
      setSerieData,
      updateData,
      setAreaSearchValue,
      setAuthorSearchValue,
      setSerieSearchValue,
      setDateData,
      setOrder
    } = useContext(BookFiltersContext);

    const checkFalse = (e) => ({ ...e, checked: false });
    const checkRangeFalse = (e) => ({ ...e, start: false, end: false });
    const clearFiltersBook = () => {
      setSearchType('boolean' as SearchType);
      setAreaSearchValue('');
      setAuthorSearchValue('');
      setSerieSearchValue('');
      setAdvancedData([{ type: 'AND', text: '', exact: false }]);
      setAreaData(areaData.map(checkFalse));
      setAuthorData(authorData.map(checkFalse));
      setSerieData(serieData.map(checkFalse));
      setDateData(dateData.map(checkRangeFalse));
      setOrder('date:desc');
      updateData();
      setTimeout(() => {
        history.push(`/pesquisa/todos`);
      }, 200);
    };

    return <FooterLogoImg src={LogoIcon} onClick={clearFiltersBook} />;
  };

  const HomeLogoButtonPeriodicV2 = () => {
    const history = useHistory();

    const {
      areaData,
      authorData,
      dateData,
      setAreaData,
      setAuthorData,
      updateData,
      setAreaSearchValue,
      setAuthorSearchValue,
      setDateData,
      setOrder
    } = useContext(PeriodicFiltersContext);

    const checkFalse = (e) => ({ ...e, checked: false });
    const checkRangeFalse = (e) => ({ ...e, start: false, end: false });
    const clearFiltersPeriodic = () => {
      setSearchType('boolean' as SearchType);
      setAreaSearchValue('');
      setAuthorSearchValue('');
      setAdvancedData([{ type: 'AND', text: '', exact: false }]);
      setAreaData(areaData.map(checkFalse));
      setAuthorData(authorData.map(checkFalse));
      setDateData(dateData.map(checkRangeFalse));
      setOrder('date:desc');
      updateData();
      setTimeout(() => {
        history.push(`/pesquisa/todos`);
      }, 200);
    };

    return <FooterLogoImg src={LogoIcon} onClick={clearFiltersPeriodic} />;
  };

  const HomeLogoButtonCodesV2 = () => {
    const history = useHistory();

    const { updateData, setOrder } = useContext(PeriodicFiltersContext);
    const clearFiltersPeriodic = () => {
      setSearchType('boolean' as SearchType);
      setAdvancedData([{ type: 'AND', text: '', exact: false }]);
      setOrder('relevance');
      updateData();
      setTimeout(() => {
        history.push(`/pesquisa/todos`);
      }, 200);
    };

    return <FooterLogoImg src={LogoIcon} onClick={clearFiltersPeriodic} />;
  };

  const HomeLogoButton = () => {
    const history = useHistory();
    const { setOrder: setVideoOrder } = useContext(VideoFiltersContext);
    const { setOrder: setBookOrder } = useContext(BookFiltersContext);
    const { setOrder: setPeriodicOrder } = useContext(PeriodicFiltersContext);

    const handleClear = () => {
      setSearchType('boolean' as SearchType);
      const checkFalse = (e) => ({ ...e, checked: false });
      const checkRangeFalse = (e) => ({ ...e, start: false, end: false });
      setAreaSearchValueGlobal('');
      setAuthorSearchValueGlobal('');
      setAdvancedData([{ type: 'AND', text: '', exact: false }]);
      setTypeDataGlobal(typeDataGlobal.map(checkFalse));
      setAreaDataGlobal(areaDataGlobal.map(checkFalse));
      setAuthorDataGlobal(authorDataGlobal.map(checkFalse));
      setDateDataGlobal(dateDataGlobal.map(checkRangeFalse));
      setOrderGlobal('date:desc');

      const newFilter = new SearchFilter();
      changeFilter(newFilter);
      setSearchString('');
      setSearchScope(0);
      setVideoOrder('date:desc');
      setBookOrder('date:desc');
      setPeriodicOrder('date:desc');
      setAdvancedData([{ type: 'AND', text: '', exact: false }]);
      window.scrollTo(0, 0);
      updateDataGlobal();
      setTimeout(() => history.push(`/pesquisa/todos`), 250);
    };
    return (
      <FooterLogoImg id="element-2" src={LogoIcon} onClick={handleClear} />
    );
  };

  let HomeLogoButtonToRender = null;

  if (
    isInVideos &&
    !isInLivros &&
    !isInOutros &&
    !isInRevistasV2 &&
    !isInCodesV2
  ) {
    HomeLogoButtonToRender = <HomeLogoButtonVideo />;
  } else if (
    !isInVideos &&
    isInLivros &&
    !isInOutros &&
    !isInRevistasV2 &&
    !isInCodesV2
  ) {
    HomeLogoButtonToRender = <HomeLogoButtonBook />;
  } else if (
    !isInVideos &&
    !isInLivros &&
    !isInOutros &&
    isInRevistasV2 &&
    !isInCodesV2
  ) {
    HomeLogoButtonToRender = <HomeLogoButtonPeriodicV2 />;
  } else if (
    !isInVideos &&
    !isInLivros &&
    !isInOutros &&
    !isInRevistasV2 &&
    isInCodesV2
  ) {
    HomeLogoButtonToRender = <HomeLogoButtonCodesV2 />;
  } else if (!isInVideos && !isInLivros && !isInRevistasV2 && !isInCodesV2) {
    HomeLogoButtonToRender = <HomeLogoButton />;
  }

  let typeOptions = [
    {
      label: (
        <span style={{ color: '#fff' }}>
          Busca por <strong>Termos/Filtros</strong>
        </span>
      ),
      value: 'boolean' as SearchType
    },
    {
      label: (
        <span style={{ color: '#fff' }}>
          Pesquisa com <strong>IA</strong>
        </span>
      ),
      value: 'ai' as SearchType
    }
  ];

  if (isInCodesV2) {
    typeOptions = [
      {
        label: (
          <span style={{ color: '#fff' }}>
            Busca por <strong>Termos/Filtros</strong>
          </span>
        ),
        value: 'boolean' as SearchType
      }
    ];
  }

  return (
    <div
      style={{
        zIndex: 9,
        position: 'sticky',
        width: '100%',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: '#FFF',
        backdropFilter: 'blur(10px)',
        paddingBottom: searchType !== 'ai' ? '32px' : '0px'
      }}>
      <Container id="element-1">
        {splittedUrl.length >= 5 && !myLocation.includes('/v2/livro') ? null : (
          <>
            <Navbar>
              <ContainerLogo>{HomeLogoButtonToRender}</ContainerLogo>
              {!isInHomePage && <TabContentSelection {...{ user: {} }} />}

              <NavbarUserLogin />
            </Navbar>
            {client.search_ai ? (
              <ContentOptions>
                <span style={{ color: '#FFF', paddingRight: 40 }}>
                  <Icon type="file-search" /> Tipo de Busca:{' '}
                </span>
                <Radio.Group
                  options={typeOptions}
                  onChange={(e: any) =>
                    setSearchType(e.target.value as SearchType)
                  }
                  value={searchType}
                />
              </ContentOptions>
            ) : (
              <></>
            )}
          </>
        )}
      </Container>
      {isInSearch && <NavbarSearch />}
      {searchType === 'ai' && <></>}
      {!isInHomePage &&
        (!isInInformativoV2 || (isInInformativoV2 && isSearch)) && (
          <NavbarFilters />
        )}
    </div>
  );
};

export default withRouter(Topbar);

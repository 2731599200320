import React from 'react';

export const DescriptionGlobal = ({ item }) => {
  const description = (el: { description: string }) =>
    el && el.description ? `${el.description.substring(0, 300)}...` : '';

  if ('highlight_description' in item) {
    return item.highlight_description.map((e, i) => (
      <div
        key={i}
        className="description highlight_description"
        dangerouslySetInnerHTML={{
          __html: `...${description({ description: e })}...`
        }}
      />
    ));
  }

  return <p className="description">{description(item)}</p>;
};

import React, { useContext } from 'react';

import { GlobalFiltersContext } from '../../GlobalFiltersContext';
import { OneTimeFetch } from './use-cases/OneTimeFetch';

export const BookSerieFilter = ({}) => {
  const {
    loadingGlobal,
    serieBookVisible,
    serieBookSearchValue,
    serieBookLoading,
    serieBookLoaded,
    serieBookError,
    serieBookData,
    serieBookRI,
    setSerieBookVisible,
    setSerieBookSearchValue,
    setSerieBookLoading,
    setSerieBookLoaded,
    setSerieBookError,
    setSerieBookData,
    setSerieBookRI,
    updateData,
    setPage
  } = useContext(GlobalFiltersContext);

  return (
    <OneTimeFetch
      segment="book-serie"
      placeholder="Séries"
      loadingGlobal={loadingGlobal}
      visible={serieBookVisible}
      searchValue={serieBookSearchValue}
      loading={serieBookLoading}
      loaded={serieBookLoaded}
      error={serieBookError}
      data={serieBookData}
      ri={serieBookRI}
      setVisible={setSerieBookVisible}
      setSearchValue={setSerieBookSearchValue}
      setLoading={setSerieBookLoading}
      setLoaded={setSerieBookLoaded}
      setError={setSerieBookError}
      setData={setSerieBookData}
      setRI={setSerieBookRI}
      setPage={setPage}
      updateData={updateData}
    />
  );
};

import { Icon } from 'antd';
import React, { useContext } from 'react';
import { AreaFilter } from './components/filters/AreaFilter';
import { AuthorFilter } from './components/filters/AuthorFilter';
import { DateFilter } from './components/filters/DateFilter';
import { GlobalNavBarClear } from './styles';
import { GlobalFiltersContext } from './GlobalFiltersContext';
import { NavbarContext } from '../../../components/_shell/NavbarFilters/NavbarContext';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { TypeFilter } from './components/filters/TypeFilter';
import LoadingImg from '../../../assets/images/loading.gif';
import { OrderFilter } from './components/filters/OrderFilter';
import { PeriodicFilter } from './components/filters/PeriodicFilter';
import { AplicationTypeFilter } from './components/filters/AplicationTypeFilter';
import { BookSerieFilter } from './components/filters/BookSerieFilter';
import { BookFilter } from './components/filters/BookFilter';
import { EventFilter } from './components/filters/EventFilter';
import { VideoSerieFilter } from './components/filters/VideoSerieFilter';

const Container = styled.div`
  position: fixed;
  display: flex;
  @media (max-width: 639px) {
    position: initial;
  }
`;

const SpanLoading = styled.span`
  padding-top: 5px;
`;

const SpanInfo = styled.span`
  font-size: 16px;
  padding-top: 5px;
`;

const SpanDivider = styled.span`
  font-size: 20px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 2px;
`;

export const GlobalNavBarFilters: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const currentRoute = location.pathname;

  const {
    totalGlobal,
    isInSearchGlobal,
    loadingGlobal,

    typeData,
    areaData,
    authorData,
    dateData,
    advancedData,
    setTypeData,
    setAreaData,
    setAuthorData,
    updateData,
    setAreaSearchValue,
    setAuthorSearchValue,
    setDateData,
    setOrder,
    setPage,

    searchType
  } = useContext(GlobalFiltersContext);

  const { setSearchString } = useContext(NavbarContext);

  const activeFilters = [...(areaData || []), ...(authorData || [])].filter(
    (e) => e.checked
  ).length;
  const dateFilter = (dateData || []).filter((e) => e.start || e.end).length
    ? 1
    : 0;
  const advancedFilters = advancedData.filter((e) => e.text.length > 0).length;
  const activeCount = advancedFilters + activeFilters + dateFilter;

  const checkFalse = (e) => ({ ...e, checked: false });
  const checkRangeFalse = (e) => ({ ...e, start: false, end: false });
  const clearFilters = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('search');
    history.replace({ search: queryParams.toString() });

    // Global
    setSearchString('');
    setAreaSearchValue('');
    setAuthorSearchValue('');
    // setAdvancedData([{ type: 'AND', text: '', exact: false }]);
    setAreaData(areaData.map(checkFalse));
    setTypeData(typeData.map(checkFalse));
    setAuthorData(authorData.map(checkFalse));
    setDateData(dateData.map(checkRangeFalse));
    setPage(1);
    setOrder('date:desc');
    updateData();
  };

  return (
    <>
      {searchType === 'ai' ? (
        <></>
      ) : (
        <Container className="container-nav-bar-filters">
          {currentRoute === '/pesquisa/todos' && <TypeFilter />}
          {currentRoute !== '/conteudo/informativos' && <AreaFilter />}
          {currentRoute !== '/conteudo/informativos' && <AuthorFilter />}
          <DateFilter />
          {currentRoute === '/conteudo/revistas' && <PeriodicFilter />}
          {currentRoute === '/conteudo/revistas' && <AplicationTypeFilter />}
          {currentRoute === '/conteudo/livros' && <BookSerieFilter />}
          {currentRoute === '/conteudo/livros' && <BookFilter />}
          {currentRoute === '/conteudo/videos' && <EventFilter />}
          {currentRoute === '/conteudo/videos' && <VideoSerieFilter />}
          <GlobalNavBarClear>
            <div
              onClick={clearFilters}
              style={{
                cursor: 'pointer'
              }}>
              <Icon
                type="delete"
                style={{ fontSize: 16, marginTop: 8 }}
                className="pointer"
              />{' '}
              <span
                className="clear-right-filters"
                style={{
                  fontSize: 12,
                  color: '#323232',
                  fontWeight: 'bold',
                  cursor: 'pointer'
                }}>
                Limpar filtros à esquerda
              </span>
            </div>
          </GlobalNavBarClear>
          <SpanDivider>|</SpanDivider>
          {loadingGlobal ? (
            <SpanLoading>
              <img src={LoadingImg} />
            </SpanLoading>
          ) : (
            <SpanInfo>
              <strong>{totalGlobal || 0}</strong> itens encontrados
            </SpanInfo>
          )}
          {isInSearchGlobal ? (
            <>
              <SpanDivider>|</SpanDivider>
              <SpanInfo>Ordenação: </SpanInfo>
              <OrderFilter />
            </>
          ) : (
            <></>
          )}
        </Container>
      )}
    </>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { Badge as badge, Popover as popover } from 'antd';
import styled from 'styled-components';
import { GlobalNavBarAdvanced, GlobalNavBarContainer } from './styles';
import { GlobalFiltersContext } from '../../../pages/v2/home/GlobalFiltersContext';
import { NavbarContext } from '../NavbarFilters/NavbarContext';
import { GlobalAdvancedFilterForm } from './GlobalAdvancedFilterForm';

const Badge = styled(badge)`
  .ant-badge-count {
    background-color: #d7282f;
    color: #fff !important;
  }

  .ant-scroll-number-only-unit {
    color: #fff !important;
  }
`;

const Popover = styled(popover)`
  .ant-popover {
    left: -21px !important;
  }
  .ant-popover-inner-content {
    padding-top: 0 !important;
  }
  .ant-popover-inner {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

interface IProps {
  handleSearch: () => void;
  showBooleanInfo?: () => void;
}

export const GlobalAdvancedFilter = ({
  handleSearch,
  showBooleanInfo
}: IProps) => {
  const [countAdd, setCountAdd] = useState(0);

  useEffect(() => {
    if (advancedData.length > 1) setAdvancedVisible(true);
  }, [countAdd]);

  const { searchCount, setSearchCount, setSearchString } =
    useContext(NavbarContext);

  const {
    loadingGlobal,
    updateData,
    advancedVisible,
    advancedData,
    setAdvancedVisible,
    setAdvancedData,
    setOrder,
    setPage
  } = useContext(GlobalFiltersContext);

  const isNative = (e) => e instanceof Event;

  const handleUpdateData = () => {
    updateData();
    handleSearch();
  };

  const validateText = (text: string) => {
    const booleanCommands = [
      ' AND ',
      ' and ',
      ' OR ',
      ' or ',
      ' NOT ',
      ' not ',
      `"`,
      `'`,
      '+'
    ];
    let isExists = false;
    for (const b of booleanCommands) {
      if (String(text).includes(b)) {
        isExists = true;
      }
    }
    return isExists;
  };

  const changeTextFrom = (e: any, index: number) => {
    if (showBooleanInfo && index === 0) {
      if (validateText(String(e.target.value))) {
        showBooleanInfo();
      }
    }
    const up = [...advancedData];
    up[index].text = e.target.value;
    setAdvancedData(up);
    setPage(1);

    if (index === 0 && isNative) {
      setSearchCount(searchCount + 1);
      setOrder('relevance');
      setSearchString(e.target.value);
    }
  };

  const changeTypeFrom = (val: any, index: number) => {
    const up = [...advancedData];
    up[index].type = val;
    setAdvancedData(up);
    setPage(1);
    if (up[index].text.length > 0) handleUpdateData();
  };

  const changeCheckboxFrom = (val: any, index: number) => {
    const up = [...advancedData];
    up[index].exact = val;
    setAdvancedData(up);
    setPage(1);
    if (up[index].text.length > 0) handleUpdateData();
  };

  const del = (index: number) => {
    const up = [...advancedData];
    up.splice(index, 1);
    if (up.length === 1) {
      up[0].type = 'AND';
      setAdvancedVisible(false);
    }
    setAdvancedData(up);
    setPage(1);
    handleUpdateData();
  };

  const add = () => {
    setAdvancedData([...advancedData, { type: 'AND', text: '', exact: false }]);
    setCountAdd(countAdd + 1);
  };

  const contentAdvanced = (advancedData || []).filter((e, i) => i !== 0);

  const content = (
    <GlobalNavBarAdvanced className="global-navbar-advanced-popover">
      {(contentAdvanced || []).map((d, i) => (
        <GlobalAdvancedFilterForm
          changeTypeFrom={changeTypeFrom}
          changeTextFrom={changeTextFrom}
          changeCheckboxFrom={changeCheckboxFrom}
          del={del}
          add={add}
          update={handleUpdateData}
          i={i + 1}
          d={d}
          loadingGlobal={loadingGlobal}
        />
      ))}
    </GlobalNavBarAdvanced>
  );

  return (
    <GlobalNavBarContainer>
      <Popover
        style={{ zIndex: 1031, left: '-21px' }}
        trigger="click"
        placement="bottom"
        visible={advancedVisible}
        content={content}
        onVisibleChange={() => {
          if ((advancedData || []).length < 2) {
            return setAdvancedVisible(false);
          }
          setAdvancedVisible(!advancedVisible);
        }}
        getPopupContainer={(trigger) => trigger}>
        <Badge
          style={{ width: 22 }}
          count={
            (advancedData || []).length > 1 ? (advancedData || []).length : 0
          }
          // offset={[20, 2]}
          offset={[-8, -2]}
          overflowCount={9}>
          <GlobalAdvancedFilterForm
            changeTypeFrom={changeTypeFrom}
            changeTextFrom={changeTextFrom}
            changeCheckboxFrom={changeCheckboxFrom}
            del={del}
            add={add}
            update={handleUpdateData}
            i={0}
            d={advancedData[0]}
            showArrow={(advancedData || []).length > 1}
            loadingGlobal={loadingGlobal}
          />
        </Badge>
      </Popover>
    </GlobalNavBarContainer>
  );
};

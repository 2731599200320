export const getItemType = (itemType: string) => {
  switch (itemType) {
    case 'jurisprudence':
      return 'Jurisprudência';
    case 'jurisprudential-trends':
      return 'Jurisprudência';
    case 'doctrine':
      return 'Doutrina';
  }
  return '';
};

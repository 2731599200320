import React, { useContext, useEffect, useRef, useState } from 'react';
import { Icon, Row } from 'antd';

import { ContentListGlobalAI } from '../contentList/contentListGlobal/ContentListGlobalAI';
import {
  ChatSpan,
  ChatSpanRight,
  ColIA,
  ColResult,
  HeaderResults,
  HrResult,
  IAChatContainer,
  IconMessage,
  IconMessageRight,
  MessageContainer,
  MessageContainerRight,
  RefineThreeContainer
} from './styles';
import { getAISearch } from '../contentList/contentListGlobal/services/getAISearch';
import { GlobalFiltersContext } from '../home/GlobalFiltersContext';
import arrowIcon from '../../../assets/images/arrow-icon-gray.png';
import { NavbarSearchIA } from '../../../components/_shell/NavbarSearch/NavbarSearchIA';
import { IAInitialPage } from './IAInitialPage';

export const IAChat = () => {
  const DEFAULT_AI_MESSAGE = 'Desculpe, não consegui compreender sua mensagem.';

  const [lastPrompt, setLastPrompt] = useState<string>('');

  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  const {
    aiSearchText,
    loadingAI,
    setLoadingAI,
    aiChat,
    setAIChat,
    submitAI,
    lastAIResult,
    setLastAIResult,
    setAiSearchText
  } = useContext(GlobalFiltersContext);

  useEffect(() => {
    scrollToBottom();
  }, [aiChat]);

  useEffect(() => {
    if (submitAI) {
      fetchSearchAI();
    }
  }, [submitAI]);

  useEffect(() => {
    if (Object.keys(lastAIResult).length === 0) return;
    const newAiChatResponse = JSON.parse(JSON.stringify(aiChat));
    const iaText = lastAIResult.message || DEFAULT_AI_MESSAGE;
    newAiChatResponse.push({
      type: 'ai',
      message: iaText
    });

    setAIChat(newAiChatResponse);
  }, [lastAIResult]);

  const fetchSearchAI = async () => {
    if (!aiSearchText) return;
    setLastPrompt(aiSearchText);
    setLoadingAI(true);
    const newAiChat = JSON.parse(JSON.stringify(aiChat));
    newAiChat.push({
      type: 'user',
      message: aiSearchText
    });
    setAIChat(newAiChat);

    const data: { message: string; conversation_id?: string } = {
      message: `${aiSearchText}`
    };

    if (lastAIResult && lastAIResult.conversation_id) {
      data.conversation_id = lastAIResult.conversation_id;
    }

    setAiSearchText('');
    const response = await getAISearch(data);
    if (response && response.data) {
      const d = response.data;
      if (d.data && String(d.data).toLowerCase() === 'not found') {
        const message = d.message ? d.message : DEFAULT_AI_MESSAGE;
        newAiChat.push({
          type: 'ai',
          message
        });
        setAIChat(newAiChat);
        setLastAIResult({});
        setLoadingAI(false);

        return;
      }
      setLastAIResult(d);
    }

    setLoadingAI(false);
  };

  return (
    <div style={{ marginBottom: 50 }}>
      <Row>
        <ColIA span={12}>
          <HeaderResults>
            <strong>Chat</strong>
          </HeaderResults>
          <HrResult style={{ marginLeft: -25 }} />
          <IAChatContainer ref={messagesEndRef}>
            {aiChat.length > 0 ? (
              <>
                {aiChat.map((c: any, i: number) => {
                  if (c.type === 'user') {
                    return (
                      <div
                        key={`chat-user-${i}`}
                        style={{ display: 'contents' }}>
                        <ChatSpanRight>VOCÊ</ChatSpanRight>
                        <div>
                          <IconMessageRight src={arrowIcon} />
                          <MessageContainerRight>
                            <span>{c.message}</span>
                          </MessageContainerRight>
                        </div>
                      </div>
                    );
                  }
                  if (c.type === 'ai') {
                    return (
                      <div key={`chat-ai-${i}`} style={{ display: 'contents' }}>
                        <ChatSpan>IA</ChatSpan>
                        <div>
                          <IconMessage src={arrowIcon} />
                          <MessageContainer>
                            <span
                              dangerouslySetInnerHTML={{ __html: c.message }}
                            />
                          </MessageContainer>
                        </div>
                      </div>
                    );
                  }
                })}
                {loadingAI ? <Icon type="loading" /> : <></>}
              </>
            ) : (
              <IAInitialPage />
            )}
            <div ref={messagesEndRef}></div>
          </IAChatContainer>
          <NavbarSearchIA helpLabel={!aiChat.length} />
        </ColIA>
        <ColResult span={12}>
          <HeaderResults>
            <strong>Resultados Encontrados</strong>
          </HeaderResults>
          <HrResult style={{ marginLeft: -20, marginRight: -20 }} />
          <ContentListGlobalAI
            isAIChat={true}
            aiChatFilters={lastAIResult}
            searchAIPrompt={lastPrompt}
          />
        </ColResult>
      </Row>
    </div>
  );
};

import React from 'react';
import { Image as SCImage, ImageAI } from '../../book/styles';
import {
  InformativeCover,
  InformativeCoverContainer,
  InformativeCoverContainerAI
} from '../../informatives/styles';
import ImageCover from '../../../../assets/images/capa-jacoby.png';

type IImgError = React.SyntheticEvent<HTMLImageElement, Event>;

export const Image = ({
  item,
  isAIChat
}: {
  item: any;
  isAIChat?: boolean;
}) => {
  if (item.type === 'informative') {
    const textSplited = String(item.title).split(': ');
    const informativeDate = textSplited[1] || '';
    const InformativeCoverComponent = isAIChat
      ? InformativeCoverContainerAI
      : InformativeCoverContainer;
    return (
      <InformativeCoverComponent
        style={{
          backgroundImage: `url(${ImageCover})`
        }}>
        <InformativeCover>
          <strong>{informativeDate}</strong>
        </InformativeCover>
      </InformativeCoverComponent>
    );
  }

  const base = `https://${process.env.REACT_APP_PUBLIC_BUCKET}.s3-sa-east-1.amazonaws.com`;
  const placeholder = `${base}/books/cover/no-cover.jpg`;
  const onImgError = (e: IImgError) => (e.currentTarget.src = placeholder);
  const idSplited = String(item.id || '').split('-');
  const globalNumId = idSplited && idSplited[0] ? idSplited[0] : '';
  const editionNumId = idSplited && idSplited[1] ? idSplited[1] : '';
  let coverSrc = '';
  if (item.type === 'book') {
    coverSrc = `${base}/books/cover/${globalNumId}/editions/${editionNumId}.jpg`;
  }
  if (item.type === 'periodic') {
    coverSrc = `${base}/periodics/cover/${globalNumId}/${globalNumId}.jpg`;
  }
  if (item.type === 'video') {
    coverSrc = `${base}/videos/thumbs/${String(globalNumId).replaceAll(
      'V',
      ''
    )}-1.jpg`;
  }

  return isAIChat ? (
    <ImageAI src={`${coverSrc}`} alt={item.title} onError={onImgError} />
  ) : (
    <SCImage src={`${coverSrc}`} alt={item.title} onError={onImgError} />
  );
};

import { AxiosResponse } from 'axios';
import { http_client } from '../../../../../util/http_client';
import { Occurrences } from '../types/occurrences';

const BASE_URL = `${process.env.REACT_APP_ENTITY_SEARCH}/global`;

interface IParams {
  order: string;
  page: number | Number;
  pageSize: number;
  filter: string;
  searchScope: number;
}

export const getAIGlobal = async (
  data: IParams,
  filters: any,
  isAIChat: boolean = false,
  searchAIPrompt: string = ''
): Promise<AxiosResponse<Occurrences>> => {
  const { order, page = 1, pageSize } = data;
  // const searchInTitle = searchScope === 0 ? false : true;
  const body = {
    page,
    size: pageSize || 10,
    order: order || 'date:desc',
    searchOrigin: isAIChat ? 'aichat' : 'boolean',
    searchAIPrompt,
    filters: {
      area: filters.area || [],
      author: filters.author || [],
      type: filters.type || [],
      book: filters.book || [],
      serie: filters.serie || [],
      periodic: filters.periodic || [],
      event: filters.event || [],
      appType: filters.appType || [],
      date: filters.date || [],
      advanced: filters.advanced || []
    }
  };

  try {
    const response = await http_client.post<Occurrences>(BASE_URL, body);

    if (response.status !== 200) {
      throw new Error(
        `A requisição falhou com o código de estado ${response.status}`
      );
    }

    return response;
  } catch (error) {
    // if (axios.isCancel(error)) {
    //   throw new Error('A requisição foi cancelada pelo usuário.');
    // }

    // throw new Error(
    //   'Falha ao buscar videos. Por favor, tente novamente mais tarde.'
    // );

    // TODO: retornar um erro amigável e retentar em x segundos.
    return {
      status: 0,
      data: {
        currentPage: 0,
        pages: 0,
        perPage: 0,
        total: 0,
        hits: []
      }
    } as AxiosResponse<Occurrences>;
  }
};

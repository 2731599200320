import React from 'react';
import { AreasFilter } from '../../../pages/pesquisa/components/filters/AreasFilter';
import { AuthorFilter } from '../../../pages/pesquisa/components/filters/AuthorFilter';
import { PeriodicFilter } from '../../../pages/pesquisa/components/filters/PeriodicFilter';
import { NewsletterFilter } from '../../../pages/pesquisa/components/filters/NewsletterFilter';
import { CodeFilter } from '../../../pages/pesquisa/components/filters/CodeFilter';
import { CodesNavBarFilters } from '../../../pages/v2/codes/CodesNavBarFilters';
import { useHistory, useRouteMatch } from 'react-router';
import { useSearchFilter } from '../../../pages/pesquisa/hooks/useSearchFilter';
import { SearchFilter } from '../../../models/search/SearchFilter';
import styled from 'styled-components';
import { Popover, Button, Badge as badge, Icon } from 'antd';
import { GlobalNavBarFilters } from '../../../pages/v2/home/GlobalNavBarFilters';

const Badge = styled(badge)`
  .ant-badge-count {
    background-color: #d7282f;
    color: #fff !important;
  }

  .ant-scroll-number-only-unit {
    color: #fff !important;
  }
`;

const Container = styled.div`
  margin-left: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  z-index: 10;
  display: flex;

  .btn-filter {
    color: #004c92;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin-right: 16px;
    border: 2px solid #004c92;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    padding: 4px 16px;
    height: 35.2px;
    transition: all 0.1s ease;
  }

  .btn-filter:hover {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin-right: 16px;
    border: 2px solid #004c92;
    border-radius: 8px;
    background-color: #004c92;
    cursor: pointer;
    padding: 4px 16px;
    height: 35.2px;
  }

  .btn-clear {
    color: #e43339;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin-right: 16px;
    border: 2px solid #e43339;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    padding: 4px 16px;
    height: 35.2px;
  }
`;

const NavbarFilters: React.FC = () => {
  const isInGlobal = location.pathname.startsWith('/pesquisa/todos');
  const isInRevistas = location.pathname.startsWith('/pesquisa/revistas');
  const isInLivros = location.pathname.startsWith('/conteudo/livros');
  const isInRevistasV2 = location.pathname.startsWith('/conteudo/revistas');
  const isInCodigosV2 = location.pathname.startsWith('/conteudo/codigos');
  const isInInformativosV2 = location.pathname.startsWith(
    '/conteudo/informativos'
  );
  const isInInformativos = location.pathname.startsWith(
    '/pesquisa/informativos'
  );
  const isInCodigos = location.pathname.startsWith('/pesquisa/codigos');
  const isInLegislacao = location.pathname.startsWith(
    '/pesquisa/legislacao-comentada'
  );
  const isInVideos = location.pathname.startsWith('/conteudo/videos');

  const pathname = String(location.pathname).split('/');
  const isVideoView = isInVideos && pathname.length > 3;

  const match = useRouteMatch();
  const history = useHistory();
  const { filter, changeFilter } = useSearchFilter();

  const handleClear = () => {
    const [, pathPart1, pathPart2] = match.url.split('/');
    const newFilter = new SearchFilter();
    changeFilter(newFilter);
    history.push(`/${pathPart1}/${pathPart2}`);
  };

  return (
    <Container>
      {isInGlobal && <GlobalNavBarFilters />}
      {isInLivros && <GlobalNavBarFilters />}
      {isInRevistasV2 && <GlobalNavBarFilters />}
      {isInCodigosV2 && <CodesNavBarFilters />}
      {isInInformativosV2 && <GlobalNavBarFilters />}
      {isInVideos && !isVideoView && <GlobalNavBarFilters />}
      {(isInRevistas || isInLegislacao) && (
        <Popover
          overlayStyle={{
            boxShadow:
              '0 8px 16px -4px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 76, 146, 0.2)',
            borderRadius: '4px'
          }}
          trigger="click"
          content={<AreasFilter />}>
          <Badge
            offset={[-17, 1]}
            count={filter.getBadgeCount().areasInterestIdsList}>
            <Button className="btn-filter">Áreas de interesse</Button>
          </Badge>
        </Popover>
      )}
      {isInRevistas && (
        <Popover
          overlayStyle={{
            boxShadow:
              '0 8px 16px -4px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 76, 146, 0.2)',
            borderRadius: '4px'
          }}
          trigger="click"
          placement="bottom"
          content={<AuthorFilter />}>
          <Badge offset={[-17, 1]} count={filter.getBadgeCount().authorsIdList}>
            <Button className="btn-filter">Autores</Button>
          </Badge>
        </Popover>
      )}
      {isInRevistas && (
        <Popover
          overlayStyle={{
            boxShadow:
              '0 8px 16px -4px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 76, 146, 0.2)',
            borderRadius: '4px'
          }}
          trigger="click"
          placement="bottom"
          content={<PeriodicFilter />}>
          <Badge
            offset={[-17, 1]}
            count={filter.getBadgeCount().periodicIdList}>
            <Button className="btn-filter">Revista</Button>
          </Badge>
        </Popover>
      )}
      {isInInformativos && (
        <Popover
          overlayStyle={{
            boxShadow:
              '0 8px 16px -4px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 76, 146, 0.2)',
            borderRadius: '4px'
          }}
          trigger="click"
          placement="bottom"
          content={<NewsletterFilter />}>
          <Badge offset={[-17, 1]} count={filter.getBadgeCount().codeIdList}>
            <Button className="btn-filter">Filtros</Button>
          </Badge>
        </Popover>
      )}
      {isInCodigos && (
        <Popover
          overlayStyle={{
            boxShadow:
              '0 8px 16px -4px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 76, 146, 0.2)',
            borderRadius: '4px'
          }}
          trigger="click"
          placement="bottom"
          content={<CodeFilter />}>
          <Badge offset={[-17, 1]} count={filter.getBadgeCount().codeIdList}>
            <Button className="btn-filter">Código</Button>
          </Badge>
        </Popover>
      )}
      {isInLegislacao && (
        <Popover
          overlayStyle={{
            boxShadow:
              '0 8px 16px -4px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 76, 146, 0.2)',
            borderRadius: '4px'
          }}
          trigger="click"
          placement="bottom"
          content={<CodeFilter />}>
          <Button className="btn-filter">Legislação</Button>
        </Popover>
      )}
      {(isInRevistas || isInInformativos || isInCodigos || isInLegislacao) && (
        <div
          onClick={handleClear}
          style={{
            cursor: 'pointer'
          }}>
          <Icon
            type="delete"
            style={{ fontSize: 16, marginTop: 8 }}
            className="pointer"
          />{' '}
          <span
            style={{
              fontSize: 12,
              color: '#323232',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}>
            Limpar filtros à esquerda
          </span>
        </div>
      )}
    </Container>
  );
};

export default NavbarFilters;

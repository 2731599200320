import React, { useContext } from 'react';

import { GlobalFiltersContext } from '../../GlobalFiltersContext';
import { useQuery } from '../../../_shared/useQuery';
import { FixedData } from './use-cases/FixedData';

export const AplicationTypeFilter = ({}) => {
  const {
    appTypeVisible,
    appTypeSearchValue,
    appTypeData,
    setAppTypeVisible,
    setAppTypeSearchValue,
    setAppTypeData,
    updateData,
    setPage
  } = useContext(GlobalFiltersContext);

  const search = String(useQuery().get('search') || '').trim();

  if (!search.length) return <></>;

  return (
    <FixedData
      placeholder="Tipo"
      filterable={false}
      visible={appTypeVisible}
      searchValue={appTypeSearchValue}
      data={appTypeData}
      setVisible={setAppTypeVisible}
      setSearchValue={setAppTypeSearchValue}
      setData={setAppTypeData}
      updateData={updateData}
      setPage={setPage}
    />
  );
};

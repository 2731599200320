import { http_client } from '../../../../../util/http_client';

const BASE_URL = `${process.env.REACT_APP_ENTITY_SEARCH}/global/ai-search`;

interface IParams {
  message: string;
  conversation_id?: string;
}

export const getAISearch = async (data: IParams): Promise<any> => {
  try {
    const response = await http_client.post(BASE_URL, data);
    if (response.status !== 200) {
      return { data: 'Not found' };
    }
    return response;
  } catch (error) {
    return { data: 'Not found' };
  }
};

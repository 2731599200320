import React, { useContext } from 'react';

import { GlobalFiltersContext } from '../../GlobalFiltersContext';
import { OneTimeFetch } from './use-cases/OneTimeFetch';

export const TypeFilter = ({}) => {
  const {
    loadingGlobal,
    typeVisible,
    typeSearchValue,
    typeLoading,
    typeLoaded,
    typeError,
    typeData,
    typeRI,
    setTypeVisible,
    setTypeSearchValue,
    setTypeLoading,
    setTypeLoaded,
    setTypeError,
    setTypeData,
    setTypeRI,
    updateData,
    setPage
  } = useContext(GlobalFiltersContext);

  return (
    <OneTimeFetch
      segment="type"
      placeholder="Tipo de Conteúdo"
      loadingGlobal={loadingGlobal}
      visible={typeVisible}
      searchValue={typeSearchValue}
      loading={typeLoading}
      loaded={typeLoaded}
      error={typeError}
      data={typeData}
      ri={typeRI}
      setVisible={setTypeVisible}
      setSearchValue={setTypeSearchValue}
      setLoading={setTypeLoading}
      setLoaded={setTypeLoaded}
      setError={setTypeError}
      setData={setTypeData}
      setRI={setTypeRI}
      updateData={updateData}
      setPage={setPage}
    />
  );
};
